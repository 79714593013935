import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { routesEnum } from 'pages/Routes';
import { IError, ISuccessResponse } from './types';
import cookie from 'utils/cookie';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  withCredentials: false,
});

export const axiosDirectly = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  withCredentials: false,
});

const handleSuccess = (res: AxiosResponse<ISuccessResponse>) => {
  if (res.data?.meta?.code && res.data?.meta?.code < 200 && res.data?.meta?.code > 299) {
    return Promise.reject(res.data);
  }
  const result = res.data;
  if (result?.meta?.pagination) {
    if (result?.summary) {
      result.data = {
        list: result.data,
        pagination: result?.meta?.pagination,
        summary: result?.summary,
      };
    } else {
      result.data = {
        list: result.data,
        pagination: result?.meta?.pagination,
      };
    }
  }
  return result;
};

const handleError = (err: AxiosError<IError>) => {
  //we not redirect when request is login api
  if ((err?.response?.data.meta.code === 401 || err?.response?.data.meta.code === 403) && err.config.url !== '/login') {
    if (window.location.pathname !== routesEnum.login) {
      localStorage.clear();
      cookie.removeCookie(cookie.COOKIES.token);
      window.location.href = routesEnum.login;
    }
  }
  const data = err?.response?.data;
  if (typeof data?.meta?.message === 'string') {
    data.meta.message = [data.meta.message];
  }
  return Promise.reject(data);
};

axiosInstance.interceptors.response.use(handleSuccess, handleError);
axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = cookie.getCookie(cookie.COOKIES.token);
    config = {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // data: convertToFormData(config.data),
    };

    return config;
  },
  (error) => Promise.reject(error)
);

axiosDirectly.interceptors.response.use(handleSuccess, handleError);
axiosDirectly.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = cookie.getCookie(cookie.COOKIES.token);
    config = {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: convertToFormData(config.data),
    };

    return config;
  },
  (error) => Promise.reject(error)
);

const convertToFormData = (data: { string: string }) => {
  const bodyFormData = new FormData();
  if (data) {
    for (const [key, value] of Object.entries(data)) {
      bodyFormData.append(key, value);
    }
  }
  return bodyFormData;
};
