import { shortenAddress, useEtherBalance } from '@usedapp/core';
import { Image, Modal, Space, Typography } from 'antd';
import clsx from 'clsx';
import { Button } from 'components/Button';
import { formatEther } from 'ethers/lib/utils';
import { useInactiveListener } from 'hooks/useEagerConnect';
import { useWallet } from 'hooks/useWallet';
import { CURRENCY } from 'lib/config';
import { requestSwitchNetwork } from 'lib/requestSwitchNetwork';
import { FC, useState } from 'react';
import { TYPE_CHAIN } from 'utils/constant';
import Identicon from './Identicon';
import styles from './styles.module.less';

export const HeaderContent: FC = () => {
  const { chainId, account, error, connect, isLoading, deactivate } = useWallet();
  const etherBalance = useEtherBalance(account);
  const isMainnet = TYPE_CHAIN === 'mainnet';
  const [isShowWalletProviders, setIsShowWalletProviders] = useState(false);

  useInactiveListener();

  const handleSwitchNetwork = async () => {
    await requestSwitchNetwork(Number(Object.keys(CURRENCY)[0]));
  };

  const handleConnectWallet = async (walletType) => {
    if (isLoading) return;
    return await connect(walletType);
  };

  if (
    (account && chainId !== (isMainnet ? 137 : 80001)) ||
    String(error?.name) === 'UnsupportedChainIdError' ||
    String(error?.name) === 't'
  )
    return <Button onClick={handleSwitchNetwork}>Change to Polygon</Button>;
  if (!account || chainId !== (isMainnet ? 137 : 80001))
    return (
      <>
        <Button onClick={() => setIsShowWalletProviders(true)}>Connect wallet</Button>
        <Modal
          visible={isShowWalletProviders}
          title=""
          width={425}
          maskClosable={false}
          style={{ zIndex: 10 }}
          footer={null}
          onCancel={() => setIsShowWalletProviders(false)}
        >
          <Typography.Title level={4} style={{ textAlign: 'center' }}>
            Select a wallet
          </Typography.Title>
          <div className={styles.containerProviders}>
            <div
              className={clsx(styles.boxProvider, styles.boxMetamask)}
              onClick={() => handleConnectWallet('injected')}
            >
              <div>Metamask</div>
              <Image preview={false} src="/images/metamask.png" width={40} height={40} alt="" />
            </div>
            <div
              className={clsx(styles.boxProvider, styles.boxWalletConnect)}
              onClick={() => handleConnectWallet('walletconnect')}
            >
              <div>Connect wallet</div>
              <Image preview={false} src="/images/walletconnect.png" width={40} height={40} alt="" />
            </div>
          </div>
        </Modal>
      </>
    );

  return (
    <Space>
      <Button
        className={clsx(styles.button, 'f-between')}
        // onClick={openAccountModal}
      >
        <Space align="center">
          <Typography style={{ paddingTop: 2 }}>
            {`${Number(formatEther(etherBalance || 0)).toFixed(2)} ${chainId ? CURRENCY[chainId] : ''}`}
          </Typography>
          <p>{account ? shortenAddress(account) : ''}</p>
          <Identicon />
        </Space>
      </Button>
      <Button className={clsx(styles.button)} onClick={() => deactivate()}>
        Disconnect
      </Button>
    </Space>
  );
};
