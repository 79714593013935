//NODE_ENV
export const API_URI = process.env.NEXT_PUBLIC_API_BASE_URL as string;
export const POLYGON_SCAN = process.env.REACT_APP_POLYGON_SCAN as string;

export const POLYGON_RPC = process.env.REACT_APP_RPC as string;
export const MARKET_ADDRESS = process.env.REACT_APP_WALLET_ADDRESS as string;

export const walletConnectProjectId = process.env.REACT_APP_WALLET_CONNECT_PROJECt_ID as string;

export const CONTRACT_ADDRESS = {
  TOKEN: process.env.REACT_APP_TOKEN_ADRESS as string,
  NFT: process.env.REACT_APP_NFT_ADRESS as string,
  NFT_GOLD: process.env.REACT_APP_NFT_GOLD_ADRESS as string,
  // MARKET: process.env.REACT_APP_MARKET_ADRESS as string,
  USDC: process.env.REACT_APP_CURRENCY_ADRESS as string,
  MANAGER: process.env.REACT_APP_MANAGER_ADRESS as string,
  CORE: process.env.REACT_APP_CORE_ADRESS as string,
  ROUTER: process.env.REACT_APP_ROUTER_ADRESS as string,
};

export const TYPE_CHAIN = process.env.REACT_APP_TYPE_CHAIN || 'testnest';
export const CURRENCY = process.env.REACT_APP_CURRENCY || 'USDT';

export const WEIGHT_PERCENT = 0.03215074656862798;
export const DECIMALS_CURRENCY = process.env.REACT_APP_DECIMALS_CURRENCY || 18;
export const DECIMALS_MATIC_CURRENCY = process.env.REACT_APP_MUMBAI_DECIMALS_CURRENCY || 18;
export const LOCAL_STORAGE = {
  token: 'token',
};

export const BIG_NUMBER = 100000;

export const NOTIFICATION_STATUS_ENUMS = {
  0: 'Inactive',
  1: 'Active',
};

export const ABOUT_STATUS = {
  0: 'Unpublish',
  1: 'Publish',
};

export const NOTIFICATION_TYPE_SEND_ENUMS = {
  0: 'All',
  1: 'Vendor',
  2: 'Platform User',
};

export const NOTIFICATION_TYPE_ENUMS = {
  0: 'Account',
  1: 'NFT transaction',
  2: 'Promotion/Marketing',
};

export const NOTIFICATION_STATUS_SENDING_ENUMS = {
  0: 'Unsent',
  1: 'Sent',
};

export const NEWS_STATUS = {
  0: 'Publish',
  1: 'Unpublish',
};

export const BRAND_STATUS_ENUMS = {
  0: 'Inactive',
  1: 'Active',
};

export const ADMIN_STATUS_ENUMS = {
  0: 'Inactive',
  1: 'Active',
};

export const PROMO_STATUS_ENUMS = {
  0: 'New',
  1: 'Inprogress',
  2: 'Pause',
  3: 'Done',
};

export const COUPON_STATUS_ENUMS = {
  0: 'New',
  1: 'Inprogress',
  2: 'Pause',
  3: 'Done',
};

export const REFERRAL_VENDOR = {
  0: 'Refferal',
  1: 'Vendor',
};

export const ARTICLE_STATUS_ENUMS = {
  0: 'Unpublished',
  1: 'Publish',
};

export const USER_ENUMS = {
  0: 'Platform User',
  1: 'Vendor',
};

export const GROUP_STATUS_ENUM = {
  0: 'Super Admin',
  1: 'Vendor',
};

export const DISCOUNT_TYPE_ENUM = {
  0: 'Service Fees',
  1: 'Price on NFT',
};

export const PROMOCODE_TYPE_ENUM = {
  0: 'Price',
  1: 'Percent',
};

export const COUPON_TYPE_ENUM = {
  0: 'Price',
  1: 'Percent',
};

export const USER_STATUS_ENUM = {
  request: 'Request',
  active: 'Active',
};

export const NFT_ONSALE_ENUM = {
  0: 'Not on Sale',
  1: 'On Sale',
};
export const ADMIN_STATUS_PERMISSION = {
  0: 'Inactive',
  1: 'Active',
};
export const KYC_STATUS = {
  0: 'Inactive',
  1: 'Active',
};

export const NFT_ACTION_ENUM = {
  mint: 'Mint',
  burn: 'Burn',
  transfer: 'Transfer',
  OrderCreated: 'Created Order',
  Buy: 'Buy',
  OrderCancelled: 'Cancelled Order',
  OrderUpdated: 'Updated Order',
  BidCreated: 'Created Bid',
  AcceptBid: 'Accept Bid',
  BidUpdated: 'Updated Bid',
  BidCancelled: 'Cancelled Bid',
  Sold: 'Trade Gold',
  SyntheticInitialOrderCancelled: 'Cancelled Order Gold',
  SyntheticInitialOrderCreated: 'Created Order Gold',
  SyntheticOrderBidAccepted: 'Accept Bid Gold',
  SyntheticOrderBidCancelled: 'Cancelled Bid Gold',
  SyntheticOrderBidCreated: 'Created Bid Gold',
  SyntheticOrderBidUpdated: 'Updated Bid Gold',
};

export const TITLE_BUSSINESS_STATICAL = {
  totalTrading: 'Total Traded Value',
  totalNFTsMinted: 'Total NFTs minted',
  totalNFTsSold: 'Total NFTs traded',
  maxPrice: 'Highest Priced NFT Sold',
};

export const ADMIN_SORT = {
  NONE: 0,
  FULLNAME_DESC: 1,
  FULLNAME_ASC: 2,
  USERNAME_DESC: 3,
  USERNAME_ASC: 4,
  EMAIL_DESC: 5,
  EMAIL_ASC: 6,
  GROUP_DESC: 7,
  GROUP_ASC: 8,
  UPDATEAT_DESC: 9,
  UPDATEAT_ASC: 10,
  CREATED_BY_DESC: 11,
  CREATED_BY_ASC: 12,
};

export const SORT_FAQ = {
  NONE: 0,
  CATEGORYNAME_DESC: 1,
  CATEGORYNAME_ASC: 2,
  QUESTION_DESC: 3,
  QUESTION_ASC: 4,
  ANSWER_DESC: 5,
  ANSWER_ASC: 6,
  UPDATEAT_DESC: 7,
  UPDATEAT_ASC: 8,
  CREATEAT_DESC: 9,
  CREATEAT_ASC: 10,
  CREATED_BY_DESC: 11,
  CREATED_BY_ASC: 12,
  UPDATED_BY_DESC: 13,
  UPDATED_BY_ASC: 14,
};

export const SORT_ABOUT = {
  NONE: 0,
  TITLE_DESC: 1,
  TITLE_ASC: 2,
  DESCRIPTION_DESC: 3,
  DESCRIPTION_ASC: 4,
  UPDATEAT_DESC: 5,
  UPDATEAT_ASC: 6,
  CREATEAT_DESC: 7,
  CREATEAT_ASC: 8,
  SECTION_DESC: 9,
  SECTION_ASC: 10,
  CREATED_BY_DESC: 11,
  CREATED_BY_ASC: 12,
  UPDATED_BY_DESC: 13,
  UPDATED_BY_ASC: 14,
};

export const BRAND_SORT = {
  NONE: 0,
  BRANDNAME_DESC: 1,
  BRANDNAME_ASC: 2,
  DESCRIPTION_DESC: 3,
  DESCRIPTION_ASC: 4,
  STATUS_DESC: 5,
  STATUS_ASC: 6,
  CATEGORYNAME_DESC: 7,
  CATEGORYNAME_ASC: 8,
  NFTSTRADED_DESC: 9,
  NFTSTRADED_ASC: 10,
  NFTSMINTED_DESC: 11,
  NFTSMINTED_ASC: 12,
  CREATEDBY_DESC: 13,
  CREATEDBY_ASC: 14,
  UPDATEDBY_DESC: 15,
  UPDATEDBY_ASC: 16,
  CREATEDAT_DESC: 17,
  CREATEDAT_ASC: 18,
  UPDATEAT_DESC: 19,
  UPDATEAT_ASC: 20,
};

export const CATEGORY_SORT = {
  NONE: 0,
  NAME_DESC: 1,
  NAME_ASC: 2,
  DESCRIPTION_DESC: 3,
  DESCRIPTION_ASC: 4,
  NFTSTRADED_DESC: 5,
  NFTSTRADED_ASC: 6,
  NFTSMINTED_DESC: 7,
  NFTSMINTED_ASC: 8,
  CREATEDBY_DESC: 9,
  CREATEDBY_ASC: 10,
  UPDATEDBY_DESC: 11,
  UPDATEDBY_ASC: 12,
  CREATEDAT_DESC: 13,
  CREATEDAT_ASC: 14,
  UPDATEAT_DESC: 15,
  UPDATEAT_ASC: 16,
};

export const COLLECTION_SORT = {
  NONE: 0,
  NAME_DESC: 1,
  NAME_ASC: 2,
  PRICEMIN_DESC: 3,
  PRICEMIN_ASC: 4,
  PRICEMAX_DESC: 5,
  PRICEMAX_ASC: 6,
  BRAND_DESC: 7,
  BRAND_ASC: 8,
  DESCRIPTION_ASC: 9,
  DESCRIPTION_DESC: 10,
  NFTSTRADED_DESC: 11,
  NFTSTRADED_ASC: 12,
  NFTSMINTED_DESC: 13,
  NFTSMINTED_ASC: 14,
  CREATEDBY_DESC: 15,
  CREATEDBY_ASC: 16,
  UPDATEDBY_DESC: 17,
  UPDATEDBY_ASC: 18,
  CREATEDAT_DESC: 19,
  CREATEDAT_ASC: 20,
  UPDATEAT_DESC: 21,
  UPDATEAT_ASC: 22,
};

export const NFT_SORT = {
  NONE: 0,
  TOKENID_DESC: 1,
  TOKENID_ASC: 2,
  NAME_DESC: 3,
  NAME_ASC: 4,
  EDITION_DESC: 5,
  EDITION_ASC: 6,
  STATUS_DESC: 7,
  STATUS_ASC: 8,
  PRICE_DESC: 9,
  PRICE_ASC: 10,
  COLLECTION_DESC: 11,
  COLLECTION_ASC: 12,
  OWNER_DESC: 13,
  OWNER_ASC: 14,
  TOTAL_TIME_TRADE_DESC: 15,
  TOTAL_TIME_TRADE_ASC: 16,
  MINT_AT_DESC: 17,
  MINT_AT_ASC: 18,
  MINT_BY_DESC: 19,
  MINT_BY_ASC: 20,
  LAST_TRADE_AT_DESC: 21,
  LAST_TRADE_AT_ASC: 22,
  LAST_TRADE_PRICE_DESC: 23,
  LAST_TRADE_PRICE_ASC: 24,
  BRANDNAME_DESC: 25,
  BRANDNAME_ASC: 26,
  UPDATEAT_DESC: 27,
  UPDATEAT_ASC: 28,
};

export const CATEGORY_FAQ = {
  NONE: 0,
  SECTION_DESC: 1,
  SECTION_ASC: 2,
  UPDATEAT_DESC: 3,
  UPDATEAT_ASC: 4,
  CREATEAT_DESC: 5,
  CREATEAT_ASC: 6,
  CREATED_BY_DESC: 7,
  CREATED_BY_ASC: 8,
  UPDATED_BY_DESC: 9,
  UPDATED_BY_ASC: 10,
  AUTHOR_DESC: 11,
  AUTHOR_ASC: 12,
};

export const USER_SORT = {
  NONE: 0,
  USERNAME_DESC: 1,
  USERNAME_ASC: 2,
  EMAIL_DESC: 3,
  EMAIL_ASC: 4,
  STATUS_DESC: 5,
  STATUS_ASC: 6,
  WALLET_DESC: 7,
  WALLET_ASC: 8,
  USER_DESC: 9,
  USER_ASC: 10,
  VENDOR_DESC: 11,
  VENDOR_ASC: 12,
  UPDATEAT_DESC: 13,
  UPDATEAT_ASC: 14,
  REFERRAL_DESC: 15,
  REFERRAL_ASC: 16,
  NFTsTraded_ASC: 17,
  NFTsTraded_DESC: 18,
  KYC_ASC: 19,
  KYC_DESC: 20,
  CREATEDAT_ASC: 21,
  CREATEDAT_DESC: 22,
  CREATEDBY_ASC: 23,
  CREATEDBY_DESC: 24,
  UPDATEDBY_ASC: 25,
  UPDATEDBY_DESC: 26,
  USERNAME_INTERNAL: 1,
  USERNAME_EXTERNAL: 2,
};

export const TRANSACTION_SORT = {
  NONE: 0,
  TOKENID_DESC: 1,
  TOKENID_ASC: 2,
  NAMENFT_DESC: 3,
  NAMENFT_ASC: 4,
  TRANSACTIONTYPE_DESC: 5,
  TRANSACTIONTYPE_ASC: 6,
  PRICE_DESC: 7,
  PRICE_ASC: 8,
  DATE_DESC: 9,
  DATE_ASC: 10,
  OWNER_DESC: 11,
  OWNER_ASC: 12,
  NFTMINTED_DESC: 13,
  NFTMINTED_ASC: 14,
  NFTBURNT_DESC: 15,
  NFTBURNT_ASC: 16,
  UPDATEAT_DESC: 17,
  UPDATEAT_ASC: 18,
};

export const PROMO_SORT_V1 = {
  NONE: 0,
  NAME_DESC: 1,
  NAME_ASC: 2,
  LIMITUSER_DESC: 3,
  LIMITUSER_ASC: 4,
  PROMOTYPE_ASC: 5,
  PROMOTYPE_DESC: 6,
  DISCOUNTTYPE_ASC: 7,
  DISCOUNTTYPE_DESC: 8,
  PRICE_DESC: 9,
  PRICE_ASC: 10,
  PERCENT_DESC: 11,
  PERCENT_ASC: 12,
  CATEGORYNAME_DESC: 13,
  CATEGORYNAME_ASC: 14,
  BRANDNAME_DESC: 15,
  BRANDNAME_ASC: 16,
  COLLECTIONNAME_DESC: 17,
  COLLECTIONNAME_ASC: 18,
  STARTTIME_DESC: 19,
  STARTTIME_ASC: 20,
  ENDTIME_DESC: 21,
  ENDTIME_ASC: 22,
  MAXAMOUNT_DESC: 23,
  MAXAMOUNT_ASC: 24,
  MINAMOUNT_DESC: 25,
  MINAMOUNT_ASC: 26,
  VENDOR_DESC: 27,
  VENDOR_ASC: 28,
  STATUS_DESC: 29,
  STATUS_ASC: 30,
  VENDORPROJECT_DESC: 31,
  VENDORPROJECT_ASC: 32,
  UPDATEAT_DESC: 33,
  UPDATEAT_ASC: 34,
};

export const COUPON_SORT_V1 = {
  NONE: 0,
  NAME_DESC: 1,
  NAME_ASC: 2,
  LIMITUSER_DESC: 3,
  LIMITUSER_ASC: 4,
  PROMOTYPE_ASC: 5,
  PROMOTYPE_DESC: 6,
  DISCOUNTTYPE_ASC: 7,
  DISCOUNTTYPE_DESC: 8,
  PRICE_DESC: 9,
  PRICE_ASC: 10,
  PERCENT_DESC: 11,
  PERCENT_ASC: 12,
  CATEGORYNAME_DESC: 13,
  CATEGORYNAME_ASC: 14,
  BRANDNAME_DESC: 15,
  BRANDNAME_ASC: 16,
  COLLECTIONNAME_DESC: 17,
  COLLECTIONNAME_ASC: 18,
  STARTTIME_DESC: 19,
  STARTTIME_ASC: 20,
  ENDTIME_DESC: 21,
  ENDTIME_ASC: 22,
  MAXAMOUNT_DESC: 23,
  MAXAMOUNT_ASC: 24,
  MINAMOUNT_DESC: 25,
  MINAMOUNT_ASC: 26,
  VENDOR_DESC: 27,
  VENDOR_ASC: 28,
  STATUS_DESC: 29,
  STATUS_ASC: 30,
  VENDORPROJECT_DESC: 31,
  VENDORPROJECT_ASC: 32,
  UPDATEAT_DESC: 33,
  UPDATEAT_ASC: 34,
  VENDOR_NAME_DESC: 35,
  VENDOR_NAME_ASC: 36,
};

export const PROMO_SORT = {
  NONE: 0,
  CODE_DESC: 1,
  CODE_ASC: 2,
  NAME_DESC: 3,
  NAME_ASC: 4,
  LIMITUSER_DESC: 5,
  LIMITUSER_ASC: 6,
  PROMOTYPE_ASC: 7,
  PROMOTYPE_DESC: 8,
  DISCOUNTTYPE_ASC: 9,
  DISCOUNTTYPE_DESC: 10,
  PRICE_DESC: 11,
  PRICE_ASC: 12,
  PERCENT_DESC: 13,
  PERCENT_ASC: 14,
  CATEGORYNAME_DESC: 15,
  CATEGORYNAME_ASC: 16,
  BRANDNAME_DESC: 17,
  BRANDNAME_ASC: 18,
  COLLECTIONNAME_DESC: 19,
  COLLECTIONNAME_ASC: 20,
  STARTTIME_DESC: 21,
  STARTTIME_ASC: 22,
  ENDTIME_DESC: 23,
  ENDTIME_ASC: 24,
  MAXAMOUNT_DESC: 25,
  MAXAMOUNT_ASC: 26,
  MINAMOUNT_DESC: 27,
  MINAMOUNT_ASC: 28,
  VENDOR_DESC: 29,
  VENDOR_ASC: 30,
  VENDORPROJECT_DESC: 31,
  VENDORPROJECT_ASC: 32,
  STATUS_DESC: 33,
  STATUS_ASC: 34,
  UPDATEAT_DESC: 35,
  UPDATEAT_ASC: 36,
};

export const COUPON_SORT = {
  NONE: 0,
  CODE_DESC: 1,
  CODE_ASC: 2,
  NAME_DESC: 3,
  NAME_ASC: 4,
  LIMITUSER_DESC: 5,
  LIMITUSER_ASC: 6,
  PROMOTYPE_ASC: 7,
  PROMOTYPE_DESC: 8,
  DISCOUNTTYPE_ASC: 9,
  DISCOUNTTYPE_DESC: 10,
  PRICE_DESC: 11,
  PRICE_ASC: 12,
  PERCENT_DESC: 13,
  PERCENT_ASC: 14,
  CATEGORYNAME_DESC: 15,
  CATEGORYNAME_ASC: 16,
  BRANDNAME_DESC: 17,
  BRANDNAME_ASC: 18,
  COLLECTIONNAME_DESC: 19,
  COLLECTIONNAME_ASC: 20,
  STARTTIME_DESC: 21,
  STARTTIME_ASC: 22,
  ENDTIME_DESC: 23,
  ENDTIME_ASC: 24,
  MAXAMOUNT_DESC: 25,
  MAXAMOUNT_ASC: 26,
  MINAMOUNT_DESC: 27,
  MINAMOUNT_ASC: 28,
  VENDOR_DESC: 29,
  VENDOR_ASC: 30,
  VENDORPROJECT_DESC: 31,
  VENDORPROJECT_ASC: 32,
  STATUS_DESC: 33,
  STATUS_ASC: 34,
  UPDATEAT_DESC: 35,
  UPDATEAT_ASC: 36,
  VENDOR_NAME_DESC: 37,
  VENDOR_NAME_ASC: 38,
};

export const VENDOR_SORT = {
  NONE: 0,
  VENDORNAME_DESC: 1,
  VENDORNAME_ASC: 2,
  DESCRIPTION_DESC: 3,
  DESCRIPTION_ASC: 4,
  CREATEDAT_DESC: 5,
  CREATEDAT_ASC: 6,
  CREATEDBY_DESC: 7,
  CREATEDBY_ASC: 8,
  UPDATEDAT_DESC: 9,
  UPDATEDAT_ASC: 10,
  UPDATEDBY_DESC: 11,
  UPDATEDBY_ASC: 12,
  TOTALUSER_DESC: 13,
  TOTALUSER_ASC: 14,
};

export const ARTICLE_SORT = {
  NONE: 0,
  TITLE_DESC: 1,
  TITLE_ASC: 2,
  PUBLISH_DATE_DESC: 3,
  PUBLISH_DATE_ASC: 4,
  CATEGORYNAME_DESC: 5,
  CATEGORYNAME_ASC: 6,
  UPDATEAT_DESC: 7,
  UPDATEAT_ASC: 8,
  CREATEAT_DESC: 9,
  CREATEAT_ASC: 10,
  CREATED_BY_DESC: 11,
  CREATED_BY_ASC: 12,
  UPDATED_BY_DESC: 13,
  UPDATED_BY_ASC: 14,
  AUTHOR_DESC: 15,
  AUTHOR_ASC: 16,
};

export const TAG_SORT = {
  NONE: 0,
  TAGNAME_ASC: 1,
  TAGNAME_DESC: 2,
  TAGVALUE_ASC: 3,
  TAGVALUE_DESC: 4,
  DESCRIPTION_ASC: 5,
  DESCRIPTION_DESC: 6,
  CATEGORY_ASC: 7,
  CATEGORY_DESC: 8,
  BRAND_ASC: 9,
  BRAND_DESC: 10,
  COLLECTION_ASC: 11,
  COLLECTION_DESC: 12,
  CREATED_AT_ASC: 13,
  CREATED_AT_DESC: 14,
  UPDATED_AT_ASC: 15,
  UPDATED_AT_DESC: 16,
  CREATED_BY_ASC: 17,
  CREATED_BY_DESC: 18,
  UPDATED_BY_ASC: 19,
  UPDATED_BY_DESC: 20,
};
export const TAX_LIST_SORT = {
  NONE: 0,
  COUNTRY_DESC: 1,
  COUNTRY_ASC: 2,
  TAX_TYPE_DESC: 3,
  TAX_TYPE_ASC: 4,
  DESCRIPTION_DESC: 5,
  DESCRIPTION_ASC: 6,
  UPDATEDAT_DESC: 7,
  UPDATEDAT_ASC: 8,
  UPDATEDBY_DESC: 9,
  UPDATEDBY_ASC: 10,
};
export const TAX_SORT = {
  NONE: 0,
  CATEGORY_DESC: 1,
  CATEGORY_ASC: 2,
  BRAND_DESC: 3,
  BRAND_ASC: 4,
  COLLECTION_DESC: 5,
  COLLECTION_ASC: 6,
  VENDOR_DESC: 7,
  VENDOR_ASC: 8,
  COUNTRY_DESC: 9,
  COUNTRY_ASC: 10,
  TAX_TYPE_DESC: 11,
  TAX_TYPE_ASC: 12,
  TAXFEE_DESC: 13,
  TAXFEE_ASC: 14,
};
export const BLACK_LIST_SORT = {
  NONE: 0,
  VENDOR_DESC: 1,
  VENDOR_ASC: 2,
  USERNAME_DESC: 3,
  USERNAME_ASC: 4,
  EMAIL_DESC: 5,
  EMAIL_ASC: 6,
  WALLET_DESC: 7,
  WALLET_ASC: 8,
  UPDATEAT_DESC: 9,
  UPDATEAT_ASC: 10,
  CREATEAT_DESC: 11,
  CREATEAT_ASC: 12,
  CREATED_BY_DESC: 13,
  CREATED_BY_ASC: 14,
  UPDATED_BY_DESC: 15,
  UPDATED_BY_ASC: 16,
};
export const COMMISSION_SORT = {
  NONE: 0,
  CATEGORY_DESC: 1,
  CATEGORY_ASC: 2,
  BRAND_DESC: 3,
  BRAND_ASC: 4,
  COLLECTION_DESC: 5,
  COLLECTION_ASC: 6,
  VENDOR_DESC: 7,
  VENDOR_ASC: 8,
  BUYER_FEES_DESC: 9,
  BUYER_FEES_ASC: 10,
  SELLER_FEES_DESC: 11,
  SELLER_FEES_ASC: 12,
};
export const NOTIFICATION_TYPE_SORT = {
  NONE: 0,
  NAME_DESC: 1,
  NAME_ASC: 2,
  CREATEDBY_DESC: 3,
  CREATEDBY_ASC: 4,
  UPDATEDBY_DESC: 5,
  UPDATEDBY_ASC: 6,
  CREATEDAT_DESC: 7,
  CREATEDAT_ASC: 8,
  UPDATEAT_DESC: 9,
  UPDATEAT_ASC: 10,
};
export const NOTIFICATION_LIST_SORT = {
  NONE: 0,
  RECIPIENT_DESC: 1,
  RECIPIENT_ASC: 2,
  NOTIFICATION_TYPE_DESC: 3,
  NOTIFICATION_TYPE_ASC: 4,
  SUBJECT_DESC: 5,
  SUBJECT_ASC: 6,
  STATUS_DESC: 7,
  STATUS_ASC: 8,
  SENDING_TIME_DESC: 9,
  SENDING_TIME_ASC: 10,
  CREATEDBY_DESC: 11,
  CREATEDBY_ASC: 12,
  UPDATEDBY_DESC: 13,
  UPDATEDBY_ASC: 14,
  CREATEDAT_DESC: 15,
  CREATEDAT_ASC: 16,
  UPDATEAT_DESC: 17,
  UPDATEAT_ASC: 18,
};
export const BANNER_SORT = {
  NONE: 0,
  TITLE_DESC: 1,
  TITLE_ASC: 2,
  DESCRIPTION_DESC: 3,
  DESCRIPTION_ASC: 4,
  TEXTBUTTON_DESC: 5,
  TEXTBUTTON_ASC: 6,
  UPDATEAT_DESC: 7,
  UPDATEAT_ASC: 8,
  CREATEAT_DESC: 9,
  CREATEAT_ASC: 10,
  CREATED_BY_DESC: 11,
  CREATED_BY_ASC: 12,
  UPDATED_BY_DESC: 13,
  UPDATED_BY_ASC: 14,
};
export const ROLE_SORT = {
  NONE: 0,
  NAME_DESC: 1,
  NAME_ASC: 2,
  DESCRIPTION_ASC: 3,
  DESCRIPTION_DESC: 4,
  CREATEDBY_DESC: 5,
  CREATEDBY_ASC: 6,
  UPDATEDBY_DESC: 7,
  UPDATEDBY_ASC: 8,
  CREATEDAT_DESC: 9,
  CREATEDAT_ASC: 10,
  UPDATEAT_DESC: 11,
  UPDATEAT_ASC: 12,
};

export const NEWS_SORT = {
  NONE: 0,
  TITLE_DESC: 1,
  TITLE_ASC: 2,
  LINK_DESC: 3,
  LINK_ASC: 4,
  SUMMARY_DESC: 5,
  SUMMARY_ASC: 6,
  CATEGORYNAME_DESC: 7,
  CATEGORYNAME_ASC: 8,
  PUBLISH_DATE_DESC: 9,
  PUBLISH_DATE_ASC: 10,
  UPDATEAT_DESC: 11,
  UPDATEAT_ASC: 12,
  CREATEAT_DESC: 13,
  CREATEAT_ASC: 14,
  CREATED_BY_DESC: 15,
  CREATED_BY_ASC: 16,
  UPDATED_BY_DESC: 17,
  UPDATED_BY_ASC: 18,
  AUTHOR_DESC: 19,
  AUTHOR_ASC: 20,
};

export const FONT_SIZE_QUILL = [
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '42px',
  '54px',
  '68px',
  '84px',
  '98px',
];

export const MUSLIM_STATUS = {
  1: 'No',
  0: 'YES',
};

export const KYT_SORT = {
  WALLET_DESC: 1,
  WALLET_ASC: 2,
};
