import { Chain, Config, MetamaskConnector, Polygon } from '@usedapp/core';
import { WalletConnectV2Connector } from '@usedapp/wallet-connect-v2-connector';
import { TYPE_CHAIN, walletConnectProjectId } from 'utils/constant';

const getAddressLink = (explorerUrl: string) => (address: string) => `${explorerUrl}/address/${address}`;

const getTransactionLink = (explorerUrl: string) => (txnId: string) => `${explorerUrl}/tx/${txnId}`;

const mumbaiExplorerUrl = 'https://mumbai.polygonscan.com';

export const Mumbai: Chain = {
  chainId: 80001,
  chainName: 'Mumbai',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0x08411ADd0b5AA8ee47563b146743C13b3556c9Cc',
  rpcUrl: 'https://polygon-testnet.public.blastapi.io',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  blockExplorerUrl: mumbaiExplorerUrl,
  getExplorerAddressLink: getAddressLink(mumbaiExplorerUrl),
  getExplorerTransactionLink: getTransactionLink(mumbaiExplorerUrl),
};

const isMainnet = TYPE_CHAIN === 'mainnet';
const chain = isMainnet ? Polygon : Mumbai;

export const config: Config = {
  // networks: [isMainnet ? Polygon : Mumbai],
  // autoConnect: true,
  readOnlyChainId: chain.chainId,
  readOnlyUrls: {
    [chain.chainId]: chain.rpcUrl!,
  },
  autoConnect: true,
  connectors: {
    metamask: new MetamaskConnector(),
    walletConnectV2: new WalletConnectV2Connector({
      projectId: walletConnectProjectId,
      chains: [chain],
      rpcMap: {
        1: chain.rpcUrl!,
      },
    }),
  },
};

export const CURRENCY = {
  [isMainnet ? Polygon.chainId : Mumbai.chainId]: 'MATIC',
};
