import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface ICommissionStore {
  editingId: any | null;
  logId: any;
}

const initialState: ICommissionStore = {
  editingId: null,
  logId: null,
};

export const commissionSlice = createSlice({
  name: 'commission',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<any | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setLogId: (state, action: PayloadAction<any | null>) => {
      return {
        ...state,
        logId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.commission.editingId;
export const getLogId = (state: RootState) => state.commission.logId;

export const { setEditingId, setLogId } = commissionSlice.actions;

export default commissionSlice.reducer;
