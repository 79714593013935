import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface IAboutStore {
  editingId: string | null;
  deletingId: string | null;
  requestParams: { name: string };
  edittingCategoryId: string | null;
  deletingCategoryId: string | null;
}

const initialState: IAboutStore = {
  editingId: null,
  deletingId: null,
  requestParams: { name: '' },
  edittingCategoryId: null,
  deletingCategoryId: null,
};

export const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setDeletingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingId: action.payload,
      };
    },
    setRequestParams: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        requestParams: action.payload,
      };
    },
    setEditingCategoryId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        edittingCategoryId: action.payload,
      };
    },
    setDeletingCategoryId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingCategoryId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.about.editingId;
export const getDeletingId = (state: RootState) => state.about.deletingId;
export const getRequestParams = (state: RootState) => state.about.requestParams;
export const getEditingCategoryId = (state: RootState) => state.about.edittingCategoryId;
export const getDeletingCategoryId = (state: RootState) => state.about.deletingCategoryId;

export const { setEditingId, setDeletingId, setRequestParams, setEditingCategoryId, setDeletingCategoryId } =
  aboutSlice.actions;

export default aboutSlice.reducer;
