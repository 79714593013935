import {
  AccountBookOutlined,
  GoldOutlined,
  GroupOutlined,
  InsertRowBelowOutlined,
  MailOutlined,
  MergeCellsOutlined,
  OneToOneOutlined,
  PartitionOutlined,
  PicCenterOutlined,
  PicRightOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  SisternodeOutlined,
  SlidersOutlined,
  SolutionOutlined,
  TagsOutlined,
  UngroupOutlined,
  UserSwitchOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { routesEnum } from 'pages/Routes';
import React from 'react';
import {
  AiOutlineDollarCircle,
  AiOutlineEdit,
  AiOutlineHome,
  AiOutlineInfoCircle,
  AiOutlinePayCircle,
  AiOutlinePlus,
  AiOutlineQuestionCircle,
  AiOutlineRise,
  AiOutlineTransaction,
  AiOutlineUserDelete,
  AiOutlineAlignCenter,
  AiOutlineCarryOut,
  AiOutlineProfile,
  AiOutlineFileProtect,
} from 'react-icons/ai';
import { BiBookContent, BiCategoryAlt, BiStore } from 'react-icons/bi';
import { FiUser, FiUsers } from 'react-icons/fi';
import { GiWineBottle } from 'react-icons/gi';
import {
  MdBusiness,
  MdHistory,
  MdOutlineAdminPanelSettings,
  MdOutlineCollections,
  MdOutlineSupportAgent,
} from 'react-icons/md';
import { Permission, SidebarEnum } from 'utils/permission';

type IRoute = {
  title: string;
  icon: React.ReactNode;
  path?: string;
  subMenu?: boolean;
  subItem?: any[];
  group?: '0' | '1';
  permission: string[];
};

export const MENU_SIDEBAR: IRoute[] = [
  {
    title: 'Dashboard',
    icon: <AiOutlineHome style={{ fontSize: '16px' }} />,
    path: routesEnum.home,
    permission: [SidebarEnum.dashboard],
  },
  {
    subMenu: true,
    title: 'Administrator',
    icon: <MdOutlineAdminPanelSettings />,
    path: routesEnum.administrator,
    permission: [SidebarEnum.administrator],
    subItem: [
      {
        title: 'List of admin groups',
        icon: <AiOutlineQuestionCircle />,
        path: routesEnum.listOfAdminGroups,
        permission: Permission.list_of_admin_group,
      },
      {
        title: 'Administrator',
        icon: <BiCategoryAlt />,
        path: routesEnum.administrator,
        permission: Permission.view_adminstrator,
      },
    ],
  },
  {
    title: 'Category',
    icon: <MdHistory style={{ fontSize: '16px' }} />,
    path: routesEnum.category,
    permission: [SidebarEnum.category],
  },
  {
    title: 'Brand',
    icon: <BiStore style={{ fontSize: '16px' }} />,
    path: routesEnum.brand,
    permission: [SidebarEnum.brand],
  },
  {
    title: 'Collections',
    icon: <MdOutlineCollections style={{ fontSize: '16px' }} />,
    path: routesEnum.collection,
    permission: [SidebarEnum.collection],
  },
  {
    title: 'Vendor',
    icon: <FiUser style={{ fontSize: '16px' }} />,
    path: routesEnum.vendor,
    permission: [SidebarEnum.vendor],
  },
  {
    title: 'Users',
    icon: <FiUsers style={{ fontSize: '16px' }} />,
    path: routesEnum.user,
    permission: [SidebarEnum.user],
  },
  {
    title: 'KYT',
    icon: <AiOutlineFileProtect style={{ fontSize: '16px' }} />,
    path: routesEnum.kyt,
    permission: [SidebarEnum.promo_code],
  },
  {
    title: 'NFTs',
    icon: <GiWineBottle style={{ fontSize: '16px' }} />,
    path: routesEnum.nft,
    group: '1',
    permission: [SidebarEnum.nft],
  },
  {
    title: 'Tag',
    icon: <TagsOutlined style={{ fontSize: '16px' }} />,
    path: routesEnum.tag,
    permission: [SidebarEnum.tag],
  },
  {
    subMenu: true,
    title: 'Content',
    icon: <BiBookContent style={{ fontSize: '16px' }} />,
    permission: [
      SidebarEnum.faq,
      SidebarEnum.faq_category,
      SidebarEnum.article,
      SidebarEnum.about_category,
      SidebarEnum.about,
      SidebarEnum.banner,
      SidebarEnum.news,
      SidebarEnum.articles_category,
    ],
    subItem: [
      {
        title: 'FAQ Category',
        icon: <BiCategoryAlt style={{ fontSize: '16px' }} />,
        path: routesEnum.faqCollection,
        permission: Permission.view_faq_category,
      },
      {
        title: 'FAQ',
        icon: <AiOutlineQuestionCircle style={{ fontSize: '16px' }} />,
        path: routesEnum.faq,
        permission: Permission.view_faq,
      },
      {
        title: 'FAQ Home',
        icon: <AiOutlineInfoCircle style={{ fontSize: '16px' }} />,
        path: routesEnum.faqHome,
        permission: Permission.view_faq,
      },
      {
        title: 'About Category',
        icon: <BiCategoryAlt style={{ fontSize: '16px' }} />,
        path: routesEnum.aboutCategory,
        permission: Permission.view_about_category,
      },
      {
        title: 'About',
        icon: <GroupOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.about,
        permission: Permission.view_about,
      },
      {
        title: 'Banner',
        icon: <InsertRowBelowOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.banner,
        permission: Permission.view_banner,
      },
      {
        title: 'Articles Category',
        icon: <MergeCellsOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.articlescategory,
        permission: Permission.view_category_articles,
      },
      {
        title: 'Articles',
        icon: <ReconciliationOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.article,
        permission: Permission.view_article,
      },
      {
        title: 'News',
        icon: <SisternodeOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.news,
        permission: Permission.view_news,
      },
    ],
  },
  {
    subMenu: true,
    title: 'Notification',
    icon: <MdOutlineSupportAgent style={{ fontSize: '16px' }} />,
    // path: routesEnum.notification,
    permission: [SidebarEnum.notification, SidebarEnum.notification_type],
    subItem: [
      {
        title: 'Type',
        icon: <AiOutlineInfoCircle style={{ fontSize: '16px' }} />,
        path: routesEnum.notifyType,
        permission: Permission.view_list_of_notification_type,
      },
      {
        title: 'List',
        icon: <BiCategoryAlt style={{ fontSize: '16px' }} />,
        path: routesEnum.notification,
        permission: Permission.view_notification_manual,
      },
    ],
  },
  {
    title: 'Black List',
    icon: <AiOutlineUserDelete style={{ fontSize: '16px' }} />,
    path: routesEnum.blacklist,
    permission: [SidebarEnum.blacklist],
  },
  {
    title: 'Service Fees',
    icon: <MdHistory style={{ fontSize: '16px' }} />,
    path: routesEnum.commission,
    permission: [SidebarEnum.service_fees],
  },
  {
    subMenu: true,
    title: 'Issued Tokens',
    icon: <AiOutlineRise style={{ fontSize: '16px' }} />,
    permission: [SidebarEnum.transaction, SidebarEnum.financial],
    subItem: [
      {
        title: 'Transaction',
        icon: <AiOutlineTransaction style={{ fontSize: '16px' }} />,
        path: routesEnum.transaction,
        permission: Permission.view_transaction,
      },
      {
        title: 'Transaction Traded',
        icon: <AiOutlineAlignCenter style={{ fontSize: '16px' }} />,
        path: routesEnum.transactionDetails,
        permission: Permission.view_financial,
      },
      {
        title: 'Vendor Distribution',
        icon: <MdBusiness style={{ fontSize: '16px' }} />,
        path: routesEnum.vendorStatical,
        permission: Permission.view_vendor_distribution,
      },
    ],
  },
  {
    subMenu: true,
    title: 'Tax',
    icon: <AccountBookOutlined style={{ fontSize: '16px' }} />,
    permission: [SidebarEnum.tax],
    subItem: [
      {
        title: 'Create Tax Type',
        icon: <AiOutlinePlus style={{ fontSize: '16px' }} />,
        path: routesEnum.taxType,
        permission: Permission.view_list_of_tax_type,
      },
      {
        title: 'Create Tax',
        icon: <AiOutlinePlus style={{ fontSize: '16px' }} />,
        path: routesEnum.tax,
        permission: Permission.view_list_of_tax_type,
      },
      {
        title: 'Set Tax',
        icon: <AiOutlineEdit style={{ fontSize: '16px' }} />,
        path: routesEnum.setTax,
        permission: Permission.view_list_tax_detail,
      },
    ],
  },
  {
    title: 'Promo Code',
    icon: <AiOutlinePayCircle style={{ fontSize: '16px' }} />,
    path: routesEnum.promoCode,
    permission: [SidebarEnum.promo_code],
  },
  {
    title: 'Coupon',
    icon: <AiOutlineCarryOut style={{ fontSize: '16px' }} />,
    path: routesEnum.coupon,
    permission: [SidebarEnum.promo_code],
  },
  {
    title: 'KYC template',
    icon: <AiOutlineProfile style={{ fontSize: '16px' }} />,
    path: routesEnum.kycTemplate,
    permission: [SidebarEnum.setting_kyc],
  },
  {
    subMenu: true,
    title: 'Wallet',
    icon: <AccountBookOutlined style={{ fontSize: '16px' }} />,
    permission: [SidebarEnum.setting_smct],
    subItem: [
      {
        title: 'Master Wallet',
        icon: <AiOutlinePlus style={{ fontSize: '16px' }} />,
        path: routesEnum.masterWallet,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Verifier Wallet',
        icon: <AiOutlineEdit style={{ fontSize: '16px' }} />,
        path: routesEnum.verifierWallet,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Gas Tank Wallet',
        icon: <PicRightOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.gasTankWallet,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Operator Wallet',
        icon: <SlidersOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.operatorWallet,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Gold Trader Wallet',
        icon: <GoldOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.goldTraderWallet,
        permission: Permission.scmt_setting_verify,
      },
    ],
  },
  {
    subMenu: true,
    title: 'Setting',
    icon: <SettingOutlined style={{ fontSize: '16px' }} />,
    permission: [SidebarEnum.setting_smct],
    subItem: [
      {
        title: 'Email',
        icon: <SolutionOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.emailConfig,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Limit transaction',
        icon: <AiOutlinePlus style={{ fontSize: '16px' }} />,
        path: routesEnum.limitTransactionSetting,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Gas Fee',
        icon: <AiOutlineEdit style={{ fontSize: '16px' }} />,
        path: routesEnum.gasFee,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Score Wallet',
        icon: <WalletOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.scoreWallet,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Gas Tank',
        icon: <OneToOneOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.SetGasTankTransactionFee,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Mailbox',
        icon: <MailOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.setMailbox,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Allowance',
        icon: <AiOutlineDollarCircle style={{ fontSize: '16px' }} />,
        path: routesEnum.allowance,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Alcohol Limitation',
        icon: <PicCenterOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.muslim,
        permission: Permission.edit_muslim,
      },
      {
        title: 'Weight',
        icon: <GoldOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.weight,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Chain Link',
        icon: <PartitionOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.chainlink,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Float Price',
        icon: <UngroupOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.floatPrice,
        permission: Permission.scmt_setting_verify,
      },
      {
        title: 'Transfer Ownership',
        icon: <UserSwitchOutlined style={{ fontSize: '16px' }} />,
        path: routesEnum.transferOwnership,
        permission: Permission.scmt_setting_verify,
      },
    ],
  },
];
