import { getMe, logout } from 'api/login';
import { routesEnum } from 'pages/Routes';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { setGroup, setPermission } from 'store/ducks/user/slice';
import { useAppDispatch } from './reduxHook';

export const useUser = (option?: UseQueryOptions<any, Error>) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { mutateAsync: mutateAsyncLogout } = useMutation(logout, {
    onSuccess: () => {
      localStorage.clear();
      dispatch(setGroup('0'));
      if (!location.pathname.includes(routesEnum.login)) {
        history.push(routesEnum.login);
      }
    },
    onError: () => {},
  });

  const { data, ...rest } = useQuery<any, Error>('/admin/health', getMe, {
    onSuccess: (value: any) => {
      const temp = value?.permissions || [];
      const permissions = temp.map((item) => item.action);
      dispatch(setPermission(permissions));
      dispatch(setGroup(value.group));

      if ((value || data) && (location.pathname.includes(routesEnum.login) || location.pathname === routesEnum.home)) {
        history.push(routesEnum.home);
      }
    },
    onError: () => {
      // localStorage.clear();
      // if (!location.pathname.includes(routesEnum.login)) {
      //   mutateAsyncLogout();
      //   history.push(routesEnum.login);
      // }
    },
    ...option,
  });

  return { user: data, ...rest };
  // return { user: data };
};
