import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface INotificationStore {
  editingId: string | null;
  deletingId: string | null;
  showingId: string | null;
  editingTypeId: string | null;
}

const initialState: INotificationStore = {
  editingId: null,
  deletingId: null,
  showingId: null,
  editingTypeId: null,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setDeletingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingId: action.payload,
      };
    },
    setShowingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        showingId: action.payload,
      };
    },
    setEditingTypeId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingTypeId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.notification.editingId;
export const getDeletingId = (state: RootState) => state.notification.deletingId;
export const getShowingId = (state: RootState) => state.notification.showingId;
export const getEditingTypeId = (state: RootState) => state.notification.editingTypeId;

export const { setEditingId, setDeletingId, setShowingId, setEditingTypeId } = notificationSlice.actions;

export default notificationSlice.reducer;
