import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface info {
  name: string;
  address: string;
  vendorId: string;
}

export interface IVendorStore {
  editingId: string | null;
  editingIdVendorRecceived: string | null;
  editingVendorTransfer: string | null;
  addressVendorTransfer: string | null;
  nameVendorTransfer: string | null;
  logId: string | null;
  viewDetailId: string | null;
  receiveId: string | null;
  viewVendorReceiveDetailId: string | null;
  deletingId: string | null;
  infoVendorTransfer?: info | null;
}

const initialState = {
  editingId: null,
  editingIdVendorRecceived: null,
  editingVendorTransfer: null,
  logId: null,
  viewDetailId: null,
  receiveId: null,
  viewVendorReceiveDetailId: null,
  deletingId: null,
} as IVendorStore;

export const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setEditingVendorTransfer: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingVendorTransfer: action.payload,
      };
    },
    setInfoVendorTransfer: (state, action: PayloadAction<null | IVendorStore['infoVendorTransfer']>) => {
      if (!action.payload) {
        return {
          ...state,
          infoVendorTransfer: null,
        };
      }
      return {
        ...state,
        infoVendorTransfer: action.payload,
      };
    },
    setEditingIdVendorReceived: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingIdVendorRecceived: action.payload,
      };
    },
    setLogId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        logId: action.payload,
      };
    },
    setDeletingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingId: action.payload,
      };
    },
    setViewDetailId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        viewDetailId: action.payload,
      };
    },
    setReceiveId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        receiveId: action.payload,
      };
    },
    setViewVendorReceivedDetailId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        viewVendorReceiveDetailId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.vendor.editingId;
export const getEditingIdVendorReceived = (state: RootState) => state.vendor.editingIdVendorRecceived;
export const getEditingVendorTransfer = (state: RootState) => state.vendor.editingVendorTransfer;
export const getInfoVendorTransfer = (state: RootState) => state.vendor.infoVendorTransfer;
export const getLogId = (state: RootState) => state.vendor.logId;
export const getViewDetailId = (state: RootState) => state.vendor.viewDetailId;
export const getViewVendorReceivedDetailId = (state: RootState) => state.vendor.viewVendorReceiveDetailId;
export const getReceiveId = (state: RootState) => state.vendor.receiveId;
export const getDeletingId = (state: RootState) => state.vendor.deletingId;

export const {
  setEditingId,
  setLogId,
  setViewDetailId,
  setViewVendorReceivedDetailId,
  setReceiveId,
  setDeletingId,
  setEditingIdVendorReceived,
  setEditingVendorTransfer,
  setInfoVendorTransfer,
} = vendorSlice.actions;

export default vendorSlice.reducer;
