import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface IHotCollectionStore {
  editingId: string | null;
  deletingId: string | null;
  collectionId: string | null;
}

const initialState: IHotCollectionStore = {
  editingId: null,
  deletingId: null,
  collectionId: null,
};

export const hotCollectionSlice = createSlice({
  name: 'hotCollection',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setDeletingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingId: action.payload,
      };
    },
    setCollectionId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        collectionId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.hotCollection.editingId;
export const getDeletingId = (state: RootState) => state.hotCollection.deletingId;
export const getCollectionId = (state: RootState) => state.hotCollection.collectionId;

export const { setEditingId, setDeletingId, setCollectionId } = hotCollectionSlice.actions;

export default hotCollectionSlice.reducer;
