import clsx from 'clsx';
import VerdantOriginal from 'icons/Verdant';
import { routesEnum } from 'pages/Routes';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.less';

export const Logo: FC<{ white?: boolean; className?: string }> = ({ white, className }) => {
  return (
    <Link className={clsx(styles.root, className, { [styles.white]: white })} to={routesEnum.home}>
      <VerdantOriginal style={{ width: '100px' }} />
    </Link>
  );
};
