import { LoadingFullpage } from 'components/Loading';
import { Redirect, Route } from 'react-router-dom';
import { routesEnum } from './Routes';

// import { useUser } from '@/api/auth';

const RequireAuth = ({ user, status, ...rest }) => {
  if (status === 'loading') {
    return <LoadingFullpage />;
  }
  if (!user) {
    return <Redirect to={routesEnum.login} />;
  }
  return <Route {...rest} />;
};

export default RequireAuth;
