import { message } from './message';

const typeTemplate = '${label} is not a valid ${type}';

export const formConfig = {
  validateMessages: {
    whitespace: '${label} is required!',
    required: '${label} is required!',
    types: {
      email: typeTemplate,
      string: typeTemplate,
    },
    string: {
      min: '${label} must be at least ${min} characters',
      max: '${label} cannot be longer than ${max} characters',
    },
  },
};

export const configForm = {
  maxImageFileSize: 5 * 1000 * 1000, //4MB
  allowedImageContentType: ['image/jpeg', 'image/png', 'image/gif'], // https://www.iana.org/assignments/media-types/media-types.xhtml#image
};

export const getUploadValueProps = (value: any) => {
  if (typeof value === 'string') {
    return {
      fileList: [
        {
          uid: '-1',
          status: 'done',
          url: value, // is imageUrl
          name: value.substring(value.lastIndexOf('/') + 1),
        },
      ],
    };
  } else return { fileList: value }; // maybe array of fileList
};

// Always use fileList (array) as value
export const getUploadValueFromEvent = (value: any) => {
  // FIXME: possibly upstream bug: https://github.com/ant-design/ant-design/issues/2423
  // flaky behavior here!
  // BUG: React dont know fileList has changed
  return value.fileList;
};

export const getUploadValueFromEventWithValidate = (value: any) => {
  const fileTypes = configForm.allowedImageContentType;
  const maxFileSize = configForm.maxImageFileSize;
  if (value.fileList.length === 0) return [];
  const { file } = value;
  if (fileTypes && !fileTypes.includes(file.type)) {
    message.error(`Only allow the following file types: ${fileTypes.map((ft: any) => ft.split('/')[1]).join(', ')}.`);
    return null;
  }
  if (maxFileSize && file.size > maxFileSize) {
    message.error(`Image must smaller than ${configForm.maxImageFileSize}MB!`);
    // antd acts weird if we return falsy value
    return null;
  }
  return value.fileList;
};

export const onPreview = async (file: any) => {
  let src = file.url;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow?.document.write(image.outerHTML);
};

export const rules = { required: true, whitespace: true };

export const convertFormValue = (data: { string: string }) => {
  const newData: any = { ...data };
  if (data) {
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === 'string') {
        newData[key] = value.trim();
      }
    }
  }
  return newData;
};

export const convertToFormData = (data: { string: string }) => {
  const bodyFormData = new FormData();
  if (data) {
    for (const [key, value] of Object.entries(data)) {
      bodyFormData.append(key, value || '');
    }
  }
  return bodyFormData;
};

export const inputProps = {
  brand: {
    name: {
      max: 100,
    },
  },
  name: {
    max: 100,
  },
  description: {
    max: 2000,
  },
};
export const typeNumber = (evt: any) => {
  const MAX = 999999999;
  if (
    (['e', 'E', '+', '-'].includes(evt.key) || evt.target.value > MAX) &&
    evt.key !== 'Backspace' &&
    evt.key !== 'Delete'
  ) {
    evt.preventDefault();
  }
};
