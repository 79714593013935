import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface IAdministratorStore {
  editingId: string | null;
  deletingId: string | null;
  requestParams: { username: string; email: string; status: number | null };
  decentralizationId: string | null;
}

const initialState: IAdministratorStore = {
  editingId: null,
  deletingId: null,
  requestParams: { username: '', email: '', status: null },
  decentralizationId: null,
};

export const administratorSlice = createSlice({
  name: 'administrator',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setDeletingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingId: action.payload,
      };
    },
    setRequestParams: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        requestParams: action.payload,
      };
    },
    setAddDecentralizationId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        decentralizationId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.administrator.editingId;
export const getDeletingId = (state: RootState) => state.administrator.deletingId;
export const getRequestParams = (state: RootState) => state.administrator.requestParams;
export const getAddDecentralizationId = (state: RootState) => state.administrator.decentralizationId;

export const { setEditingId, setDeletingId, setRequestParams, setAddDecentralizationId } = administratorSlice.actions;

export default administratorSlice.reducer;
