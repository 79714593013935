import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface ICollectionStore {
  editingId: string | null;
  viewId: string | null;
  deletingId: string | null;
  requestParams: { name: string };
  collectionId: string | null;
}

const initialState: ICollectionStore = {
  editingId: null,
  viewId: null,
  deletingId: null,
  requestParams: { name: '' },
  collectionId: null,
};
export const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setViewId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        viewId: action.payload,
      };
    },
    setDeletingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingId: action.payload,
      };
    },
    setRequestParams: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        requestParams: action.payload,
      };
    },
    setCollectionId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        collectionId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.collection.editingId;
export const getDeletingId = (state: RootState) => state.collection.deletingId;
export const getRequestParams = (state: RootState) => state.collection.requestParams;
export const getCollectionId = (state: RootState) => state.collection.collectionId;
export const getViewId = (state: RootState) => state.collection.viewId;

export const { setEditingId, setDeletingId, setRequestParams, setCollectionId, setViewId } = collectionSlice.actions;

export default collectionSlice.reducer;
