import { Layout as LayoutAntd, Menu } from 'antd';
import clsx from 'clsx';
import { useAppSelector } from 'hooks/reduxHook';
import { useUser } from 'hooks/useUser';
import VerdantOriginal from 'icons/Verdant';
import React, { FC, useMemo } from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { getPermission } from 'store/ducks/user/slice';
import { MENU_SIDEBAR } from './Menu';
import styles from './styles.module.less';
import { Logo } from 'components/Logo';
const { Sider } = LayoutAntd;

type Props = {
  collapsed: boolean;
  onCollapse: any;
  mutateLogout: any;
};

const BoxIcon: FC<{ className?: any }> = ({ className, children }) => {
  return <div className={clsx(styles.boxIcon, className)}>{children}</div>;
};

export const Sidebar: FC<Props> = ({ collapsed, onCollapse, mutateLogout }) => {
  const history = useHistory();
  const permissions = useAppSelector(getPermission);
  const { user } = useUser();
  const currentSidebar = useMemo(() => {
    if (user) {
      return MENU_SIDEBAR.filter((item) =>
        user.permissions?.map((item) => item.enum).some((item_) => item?.permission.indexOf(item_) >= 0)
      );
    } else {
      return [];
    }
  }, [user]);

  return (
    <Sider className={styles.slide} width={260} collapsed={collapsed} onCollapse={onCollapse}>
      <div className={styles.header}>
        <Logo />
      </div>
      <Menu className={styles.menu} selectedKeys={[location.pathname]} mode="inline">
        {currentSidebar.map((route, index) =>
          route?.subMenu ? (
            <Menu.SubMenu
              title={route.title}
              icon={<BoxIcon className={collapsed && styles.boxIconCollapse}>{route.icon}</BoxIcon>}
              key={index}
            >
              {route?.subItem
                ?.filter((item_) => permissions.includes(item_?.permission))
                .map((subItem) => {
                  return (
                    <Menu.Item
                      key={subItem.path}
                      icon={<BoxIcon className={collapsed && styles.boxIconCollapse}>{subItem.icon}</BoxIcon>}
                      onClick={() => history.push(subItem?.path || '')}
                    >
                      {subItem.title}
                    </Menu.Item>
                  );
                })}
            </Menu.SubMenu>
          ) : (
            <Menu.Item
              key={route.path}
              icon={<BoxIcon className={collapsed && styles.boxIconCollapse}>{route.icon}</BoxIcon>}
              onClick={() => {
                history.push(route?.path || '');
              }}
            >
              {route.title}
            </Menu.Item>
          )
        )}
      </Menu>

      <div className={styles.logout} onClick={() => mutateLogout()}>
        <BoxIcon>
          <AiOutlineLogout />
        </BoxIcon>
        Logout
      </div>
    </Sider>
  );
};
