import Cookies from 'js-cookie';

const COOKIES = {
  token: 'token',
};

const setCookie = (key: string, value: string) => {
  Cookies.set(key, value);
};

const getCookie = (key: string) => {
  return Cookies.get(key);
};

const removeCookie = (key: string) => {
  Cookies.remove(key);
};

export default {
  COOKIES,
  setCookie,
  getCookie,
  removeCookie,
};
