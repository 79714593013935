import { Result } from 'antd';
import { Button } from 'components/Button';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { routesEnum } from 'pages/Routes';

export const NotFoundPage: FC = () => {
  const history = useHistory();
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button onClick={() => history.push(routesEnum.home)} type="primary">
          Back Home
        </Button>
      }
    />
  );
};
