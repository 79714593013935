import { useEffect, useState } from 'react';
import { injected } from '../lib/connectors';
import { useWallet } from './useWallet';
import { useEthers } from '@usedapp/core';

export function useEagerConnect() {
  const { connect, active } = useWallet();

  const [tried, setTried] = useState(false);

  useEffect(() => {
    injected.isAuthorized().then(async (isAuthorized) => {
      if (isAuthorized) {
        await connect('injected');
        setTried(true);
      } else {
        setTried(true);
      }
    });
  }, []);

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function useInactiveListener(suppress: boolean = false) {
  const { active, error, activate, chainId, account } = useEthers();

  useEffect((): any => {
    const { ethereum } = window as any;
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        console.log("Handling 'connect' event");
        activate(injected).catch(() => {
          console.log({ error });
        });
      };
      const handleChainChanged = (chainId: string | number) => {
        console.log("Handling 'chainChanged' event with payload", chainId);
        activate(injected).catch(() => {
          console.log({ error });
        });
      };
      const handleAccountsChanged = (accounts: string[]) => {
        console.log(injected, 'inject', accounts);
        if (accounts.length > 0) {
          activate(injected).catch(() => {
            console.log({ error });
          });
        }
      };

      ethereum.on('connect', handleConnect);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect);
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }
  }, [active, error, suppress, activate, chainId, account]);
}
