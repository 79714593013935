import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface IFaqCategoryStore {
  editingId: string | null;
  deletingId: string | null;
  requestParams: { name: string };
}

const initialState: IFaqCategoryStore = {
  editingId: null,
  deletingId: null,
  requestParams: { name: '' },
};

export const faqCategorySlice = createSlice({
  name: 'faqCategory',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setDeletingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingId: action.payload,
      };
    },
    setRequestParams: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        requestParams: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.faqCategory.editingId;
export const getDeletingId = (state: RootState) => state.faqCategory.deletingId;
export const getRequestParams = (state: RootState) => state.faqCategory.requestParams;

export const { setEditingId, setDeletingId, setRequestParams } = faqCategorySlice.actions;

export default faqCategorySlice.reducer;
