import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface IFaqStore {
  address: string | null;
}

const initialState: IFaqStore = {
  address: null,
};

export const staticalSlice = createSlice({
  name: 'statical',
  initialState,
  reducers: {
    setAddress: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        address: action.payload,
      };
    },
  },
});

export const getAddress = (state: RootState) => state.statical.address;

export const { setAddress } = staticalSlice.actions;

export default staticalSlice.reducer;
