import { ConfigProvider as AntdConfigProvider, Layout as LayoutAntd } from 'antd';
import { logout } from 'api/login';
import { useAppDispatch } from 'hooks/reduxHook';
import { routesEnum } from 'pages/Routes';
import React, { FC, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { setGroup } from 'store/ducks/user/slice';
import { formConfig } from 'utils/form';
import { CustomHeader } from './components/Header';
import { Sidebar } from './components/Sidebar';
import styles from './styles.module.less';

const { Content } = LayoutAntd;

const BlankLayoutPages = ['/404', '/login'];

export const Layout: FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const client = useQueryClient();

  const { mutate: mutateLogout } = useMutation(logout, {
    onSuccess: () => {
      localStorage.clear();
      client.invalidateQueries('/admin/health');
      dispatch(setGroup('1'));
      history.push(routesEnum.login);
    },
    onError: () => {},
  });

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const blankLayout = useMemo((): boolean => {
    return BlankLayoutPages.includes(location.pathname);
  }, [location]);

  return (
    <AntdConfigProvider form={{ ...formConfig }}>
      <LayoutAntd className={styles.root}>
        {!blankLayout && <Sidebar collapsed={collapsed} onCollapse={onCollapse} mutateLogout={mutateLogout} />}
        <LayoutAntd
          className="site-layout"
          style={{
            marginLeft: location.pathname.includes('login') ? 0 : 'auto',
            height: '100vh',
          }}
        >
          {!blankLayout && <CustomHeader />}
          <Content className={styles.content}>{children}</Content>
          {/* <Footer style={{ textAlign: 'center' }}>Digital CMS 2022 Created by VAR</Footer> */}
        </LayoutAntd>
      </LayoutAntd>
    </AntdConfigProvider>
  );
};
