import { Web3Ethers, useEthers } from '@usedapp/core';
import { providers } from 'ethers';
import { message } from 'utils/message';

const handleError = (error: Error) => {
  if (error.name === 'NoEthereumProviderError') {
    message.error(
      'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.'
    );
  } else if (error.name === 'UserRejectedRequestError') {
    message.error('Please authorize this website to access your Ethereum account.');
  } else if ((error as any).code === -32002) {
    message.error('Already processing ethereum request Accounts. Please accept the request.');
  } else if ((error as any).code === 4001) {
    message.error('User denied account authorization.');
  } else if (error.message) {
    message.error(error.message);
  } else {
    message.error('An unknown error occurred. Check the console for more details.');
  }
};

type JsonRpcProvider = providers.JsonRpcProvider;

export type ModifiedWeb3Ethers = Omit<Web3Ethers, 'library'> & {
  library: JsonRpcProvider;
};

export const useWallet = () => {
  const { activateBrowserWallet, ...props } = useEthers() as ModifiedWeb3Ethers;

  const connect = async (type: 'injected' | 'walletconnect' | 'walletlink') => {
    try {
      if (type === 'injected') {
        return activateBrowserWallet();
      }

      if (type === 'walletconnect') {
        return activateBrowserWallet({ type: 'walletConnectV2' });
      }

      // if (type === 'walletconnect') {
      //   localStorage.removeItem('walletconnect');
      //   return await activate(walletconnect, (error) => handleError(error));
      // }

      // if (type === 'walletlink') {
      //   return await activate(walletlink, (error) => handleError(error));
      // }
    } catch (err) {
      console.log('Connect wallet err', err);
    }
  };

  const deactivate = (forceReload = false) => {
    localStorage.removeItem('walletconnect');
    props.deactivate();
    if (forceReload) window.location.reload();
  };

  return { ...props, deactivate, connect };
};
