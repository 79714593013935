import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface IFaqStore {
  editingId: string | null;
  deletingId: string | null;
  requestParams: { name: string };
}

const initialState: IFaqStore = {
  editingId: null,
  deletingId: null,
  requestParams: { name: '' },
};

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setDeletingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingId: action.payload,
      };
    },
    setRequestParams: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        requestParams: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.faq.editingId;
export const getDeletingId = (state: RootState) => state.faq.deletingId;
export const getRequestParams = (state: RootState) => state.faq.requestParams;

export const { setEditingId, setDeletingId, setRequestParams } = faqSlice.actions;

export default faqSlice.reducer;
