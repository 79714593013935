import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface ISystemStore {
  modal: string | null;
  allowanceId: string | null;
  weightId: string | null;
  emailId: string | null;
}

const initialState: ISystemStore = {
  modal: null,
  allowanceId: null,
  weightId: null,
  emailId: null,
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setModal: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        modal: action.payload,
      };
    },
    setAllowance: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        allowanceId: action.payload,
      };
    },
    setWeightId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        weightId: action.payload,
      };
    },
    setEmailId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        emailId: action.payload,
      };
    },
  },
});

export const getModal = (state: RootState) => state.system.modal;
export const getAllowance = (state: RootState) => state.system.allowanceId;
export const getWeightId = (state: RootState) => state.system.weightId;
export const getEmailId = (state: RootState) => state.system.emailId;

export const { setModal, setAllowance, setWeightId, setEmailId } = systemSlice.actions;

export default systemSlice.reducer;
