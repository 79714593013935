import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface IUserStore {
  editingId: string | null;
  deletingId: string | null;
  type: 'view' | 'edit';
  requestParams: { username: string; email: string; wallet: string; status: string };
  group: '0' | '1';
  permission: string[];
  balance: null;
}

const initialState: IUserStore = {
  editingId: null,
  deletingId: null,
  type: 'view',
  requestParams: { username: '', email: '', wallet: '', status: '' },
  group: '1',
  permission: [],
  balance: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setDeletingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingId: action.payload,
      };
    },
    setRequestParams: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        requestParams: action.payload,
      };
    },
    setType: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        type: action.payload,
      };
    },
    setGroup: (state, action: PayloadAction<'0' | '1'>) => {
      return {
        ...state,
        group: action.payload,
      };
    },
    setPermission: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        permission: action.payload,
      };
    },
    setBalance(state, action: PayloadAction<any['balance']>) {
      return {
        ...state,
        balance: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.user.editingId;
export const getDeletingId = (state: RootState) => state.user.deletingId;
export const getRequestParams = (state: RootState) => state.user.requestParams;
export const getType = (state: RootState) => state.user.type;
export const getGroup = (state: RootState) => state.user.group;
export const getPermission = (state: RootState) => state.user.permission;
export const getBalance = (state: RootState): any['balance'] => state.user.balance;

export const { setEditingId, setDeletingId, setRequestParams, setType, setGroup, setPermission, setBalance } =
  userSlice.actions;

export default userSlice.reducer;
