import { POLYGON_RPC } from 'utils/constant';
import { message } from 'antd';

const POLYGON_TESTNET = {
  chainId: '0x13881', // Hexadecimal version of 80001, prefixed with 0x
  chainName: 'POLYGON Mumbai',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  rpcUrls: [POLYGON_RPC],
};

const POLYGON_MAINNET = {
  chainId: '0x89', // Hexadecimal version of 80001, prefixed with 0x
  chainName: 'POLYGON Mumbai',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  rpcUrls: [POLYGON_RPC],
};

const NETWORKS = {
  '0x13881': POLYGON_TESTNET,
  '0x89': POLYGON_MAINNET,
};

type IAllowedNetwork = '0x89' | '0x13881';

export async function requestSwitchNetwork(chainId: number) {
  const chainIdHex = `0x${chainId.toString(16)}` as IAllowedNetwork;
  const provider = (window as any)?.ethereum;

  if (provider) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdHex }],
      });
      message.success('Network Changed!');
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [NETWORKS[chainIdHex]],
          });
        } catch (addError) {
          console.log({ addError });
        }
      }
    }
  }
}
