import { lazy } from '@loadable/component';
import { LoadingFullpage } from 'components/Loading';
import { NotFoundPage } from 'components/NotFoundPage';
import { useUser } from 'hooks/useUser';
import { FC, useEffect, useMemo } from 'react';
import { Route, RouteProps, Switch, useLocation } from 'react-router-dom';
import { Permission } from 'utils/permission';
import RequireAuth from './RequireAuth';

export enum routesEnum {
  home = '/',
  administrator = '/administrator',
  brand = '/brand',
  user = '/user',
  nft = '/nft',
  collection = '/collection',
  footer = '/footer',
  customerSupport = '/cs',
  notification = '/notification',
  login = '/login',
  hotCollection = '/hot-collection',
  article = '/article',
  blacklist = '/blacklist',
  whitelist = '/whitelist',
  commission = '/commission',
  transaction = '/transaction',
  transactionDetails = '/transaction-traded',
  business = '/business',
  faq = '/faq',
  faqCollection = '/faq-collection',
  about = '/about',
  aboutCategory = '/about-category',
  category = '/category',
  vendorStatical = '/vendor-statical',
  tax = '/tax',
  taxType = '/tax-type',
  setTax = '/set-tax',
  promoCode = '/promo-code',
  kyt = '/kyt',
  coupon = '/coupon',
  vendor = '/vendor',
  tag = '/tag',
  banner = '/banner',
  notifyType = '/notification-type',
  listOfAdminGroups = '/list-of-admin-groups',
  news = '/news',
  kycTemplate = '/kyc-template',
  setting = '/setting',
  articlescategory = '/articles-category',
  masterWallet = '/master-wallet',
  verifierWallet = '/verifier-wallet',
  limitTransactionSetting = '/limit-transaction-setting',
  gasFee = '/gas-fee-setting',
  scoreWallet = '/score-wallet',
  SetGasTankTransactionFee = '/gas-tank-transaction-fee',
  gasTankWallet = '/gas-tank-wallet',
  setMailbox = '/mail-box',
  faqHome = '/faq-home',
  allowance = '/allowance',
  operatorWallet = '/operator',
  goldTraderWallet = '/gold-trader',
  muslim = '/muslim',
  weight = '/weight',
  chainlink = '/chainlink',
  floatPrice = '/float-price',
  transferOwnership = '/transfer-ownership',
  emailConfig = '/email-config',
}

type CustomRouteProps = RouteProps & { private?: boolean; permission?: number | string | number[] };

const publishRoute: CustomRouteProps[] = [
  {
    path: [routesEnum.login],
    exact: true,
    component: lazy(() => import('./LoginPage')),
  },
];

export const routes: CustomRouteProps[] = [
  {
    path: routesEnum.administrator,
    exact: true,
    private: true,
    component: lazy(() => import('./AdministratorPage')),
    permission: Permission.view_adminstrator,
  },
  {
    path: routesEnum.blacklist,
    exact: true,
    private: true,
    component: lazy(() => import('./UserBlackListPage')),
    permission: Permission.view_blacklist,
  },
  {
    path: routesEnum.user,
    exact: true,
    private: true,
    component: lazy(() => import('./UserPage')),
    permission: Permission.view_list_of_user,
  },
  {
    path: routesEnum.about,
    exact: true,
    private: true,
    component: lazy(() => import('./AboutPage')),
    permission: Permission.view_about,
  },
  {
    path: routesEnum.article,
    exact: true,
    private: true,
    component: lazy(() => import('./ArticlePage')),
    permission: Permission.view_article,
  },
  {
    path: routesEnum.brand,
    exact: true,
    private: true,
    component: lazy(() => import('./BrandPage')),
    permission: Permission.view_brand,
  },
  {
    path: routesEnum.collection,
    exact: true,
    private: true,
    component: lazy(() => import('./CollectionPage')),
    permission: Permission.view_collection,
  },
  {
    path: routesEnum.commission,
    exact: true,
    private: true,
    component: lazy(() => import('./CommissionPage')),
    permission: Permission.view_service_fee,
  },
  {
    path: routesEnum.faqCollection,
    exact: true,
    private: true,
    component: lazy(() => import('./FaqCategoryPage')),
    permission: Permission.view_faq_category,
  },
  {
    path: routesEnum.faq,
    exact: true,
    private: true,
    component: lazy(() => import('./FaqPage')),
    permission: Permission.view_faq,
  },
  {
    path: routesEnum.faqHome,
    exact: true,
    private: true,
    component: lazy(() => import('./FaqHomePage')),
    permission: Permission.view_faq,
  },
  {
    path: routesEnum.nft,
    exact: true,
    private: true,
    component: lazy(() => import('./NFTPage')),
    permission: Permission.view_list_of_nfts,
  },
  {
    path: routesEnum.notification,
    exact: true,
    private: true,
    component: lazy(() => import('./NotificationPage')),
    permission: Permission.view_notification_manual,
  },
  {
    path: routesEnum.category,
    exact: true,
    private: true,
    component: lazy(() => import('./CategoryPage')),
    permission: Permission.view_category,
  },
  {
    path: routesEnum.transaction,
    exact: true,
    private: true,
    component: lazy(() => import('./TransactionPage')),
    permission: Permission.view_transaction,
  },
  {
    path: routesEnum.transactionDetails,
    exact: true,
    private: true,
    component: lazy(() => import('./TransactionDetailsPage')),
    permission: Permission.view_financial,
  },
  {
    path: routesEnum.home,
    exact: true,
    private: true,
    component: lazy(() => import('./BusinessPage')),
    permission: Permission.view_dashboard,
  },
  {
    path: routesEnum.vendorStatical,
    exact: true,
    private: true,
    component: lazy(() => import('./VendorStatical')),
    permission: Permission.view_vendor_distribution,
  },
  {
    path: routesEnum.tax,
    exact: true,
    private: true,
    component: lazy(() => import('./TaxPage')),
    permission: Permission.view_list_of_tax_type,
  },
  {
    path: routesEnum.taxType,
    exact: true,
    private: true,
    component: lazy(() => import('./TaxTypePage')),
    permission: Permission.view_list_of_tax_type,
  },
  {
    path: routesEnum.setTax,
    exact: true,
    private: true,
    component: lazy(() => import('./SetTaxPage')),
    permission: Permission.view_list_tax_detail,
  },
  {
    path: routesEnum.promoCode,
    exact: true,
    private: true,
    component: lazy(() => import('./PromoCodePage')),
    permission: Permission.view_list_of_project,
  },
  {
    path: routesEnum.kyt,
    exact: true,
    private: true,
    component: lazy(() => import('./KytPage')),
    permission: Permission.view_list_of_project,
  },
  {
    path: routesEnum.coupon,
    exact: true,
    private: true,
    component: lazy(() => import('./CouponPage')),
    permission: Permission.view_list_of_project,
  },
  {
    path: routesEnum.vendor,
    exact: true,
    private: true,
    component: lazy(() => import('./VendorPage')),
    permission: Permission.view_vendor,
  },
  {
    path: routesEnum.tag,
    exact: true,
    private: true,
    component: lazy(() => import('./TagPage')),
    permission: Permission.view_list_of_tags,
  },
  {
    path: routesEnum.banner,
    exact: true,
    private: true,
    component: lazy(() => import('./BannerPage')),
    permission: Permission.view_banner,
  },
  {
    path: routesEnum.notifyType,
    exact: true,
    private: true,
    component: lazy(() => import('./NotifyTypePage')),
    permission: Permission.view_list_of_notification_type,
  },
  {
    path: routesEnum.aboutCategory,
    exact: true,
    private: true,
    component: lazy(() => import('./AboutCategoryPage')),
    permission: Permission.view_about_category,
  },
  {
    path: routesEnum.listOfAdminGroups,
    exact: true,
    private: true,
    component: lazy(() => import('./ListOfAdminGroups')),
    permission: Permission.view_adminstrator,
  },
  {
    path: routesEnum.news,
    exact: true,
    private: true,
    component: lazy(() => import('./News')),
    permission: Permission.view_news,
  },
  {
    path: routesEnum.kycTemplate,
    exact: true,
    private: true,
    component: lazy(() => import('./KYCTemplate')),
    permission: Permission.list_template,
  },
  {
    path: routesEnum.articlescategory,
    exact: true,
    private: true,
    component: lazy(() => import('./ArticlesCategoryPage')),
    permission: Permission.view_category_articles,
  },
  {
    path: routesEnum.masterWallet,
    exact: true,
    private: true,
    component: lazy(() => import('./Statistical')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.masterWallet,
    exact: true,
    private: true,
    component: lazy(() => import('./Statistical')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.verifierWallet,
    exact: true,
    private: true,
    component: lazy(() => import('./VerifierWalletPage')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.limitTransactionSetting,
    exact: true,
    private: true,
    component: lazy(() => import('./LimitTransactionPage')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.gasFee,
    exact: true,
    private: true,
    component: lazy(() => import('./GasFeePage')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.scoreWallet,
    exact: true,
    private: true,
    component: lazy(() => import('./ScoreWalletPage')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.SetGasTankTransactionFee,
    exact: true,
    private: true,
    component: lazy(() => import('./SetGasTankTransactionFee')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.gasTankWallet,
    exact: true,
    private: true,
    component: lazy(() => import('./GasTankWalletPage')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.setMailbox,
    exact: true,
    private: true,
    component: lazy(() => import('./SetMailboxPage')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.allowance,
    exact: true,
    private: true,
    component: lazy(() => import('./AllowancePage')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.operatorWallet,
    exact: true,
    private: true,
    component: lazy(() => import('./OperatorWalletPage')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.goldTraderWallet,
    exact: true,
    private: true,
    component: lazy(() => import('./GoldTraderWalletPage')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.muslim,
    exact: true,
    private: true,
    component: lazy(() => import('./MuslimPage')),
    permission: Permission.edit_muslim,
  },
  {
    path: routesEnum.weight,
    exact: true,
    private: true,
    component: lazy(() => import('./WeightPage')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.chainlink,
    exact: true,
    private: true,
    component: lazy(() => import('./ChainLinkPage')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.floatPrice,
    exact: true,
    private: true,
    component: lazy(() => import('./FloatPricePage')),
    permission: Permission.scmt_setting_verify,
  },
  {
    path: routesEnum.emailConfig,
    exact: true,
    private: true,
    component: lazy(() => import('./EmailConfigPage')),
    permission: Permission.scmt_setting_verify,
  },
];

export const Routes: FC = () => {
  const location = useLocation();
  const { user, status } = useUser();
  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location]);

  const currentSidebar: CustomRouteProps[] = useMemo(() => {
    if (user) {
      return routes.filter(
        (item) =>
          user.permissions.map((_item) => _item.action).includes(item.permission) && item.path !== routesEnum.login
      );
    } else return [];
  }, [user, routes]);

  if (!location.pathname.includes(routesEnum.login) && !user) {
    return <LoadingFullpage />;
  }

  return (
    <Switch>
      {publishRoute.map(({ path, exact, component: Component }) => {
        return <Route path={path} key={path as string} exact={exact} component={Component} />;
      })}
      {currentSidebar.map(({ path, exact, component: Component }) => {
        return (
          <RequireAuth
            user={user}
            status={status}
            path={path}
            key={path as string}
            exact={exact}
            component={Component}
          />
        );
      })}
      <Route path={'*'}>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};
