import React from 'react';

const VerdantOriginal = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="157" height="32" viewBox="0 0 157 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_87_6729)">
        <mask
          id="mask0_87_6729"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="157"
          height="32"
        >
          <rect width="156.16" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_87_6729)">
          <path
            d="M30.6332 8.74319L25.1849 0.0661074V0.0578674C25.1834 0.0541549 25.1811 0.0508551 25.1781 0.0482526L25.1684 0.0358926V0.0290231L25.1547 0.0180379L25.1396 0.00704838L25.1245 0.000183105H5.58664C5.55493 0.00846136 5.52601 0.0250566 5.50287 0.0482526L5.48913 0.0633579V0.0715979L5.48227 0.0812126L0.0299031 8.74319C0.00803281 8.77767 -0.00229011 8.81822 0.000426051 8.85896C0.00314221 8.8997 0.0187544 8.93852 0.0450084 8.9698L15.1784 31.7859C15.1855 31.7939 15.1933 31.8013 15.2017 31.8079H15.2086L15.2292 31.8216H15.2388L15.2636 31.8299H15.2773C15.2896 31.8313 15.302 31.8313 15.3144 31.8299C15.3271 31.8313 15.34 31.8313 15.3528 31.8299H15.3652L15.3899 31.8216H15.4009L15.4215 31.8079H15.4284C15.4372 31.8013 15.4455 31.794 15.4531 31.7859L16.7235 29.9099L2.77668 8.81186L6.83643 2.34868H23.8143L27.874 8.81186L17.5283 24.4026L18.8701 26.6742L30.6181 8.9698C30.6447 8.93872 30.6606 8.89985 30.6633 8.85901C30.666 8.81816 30.6555 8.77753 30.6332 8.74319Z"
            fill="currentColor"
          />
          <path d="M8.80312 7.13907H6.47385L8.44467 3.73581H10.7726L8.80312 7.13907Z" fill="currentColor" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M76.6816 26.8514C78.136 27.7113 79.799 28.1541 81.4885 28.1314H81.4898C82.9244 28.1458 84.339 27.7941 85.5997 27.1094C86.8604 26.4247 87.9256 25.4298 88.6946 24.2186H88.7317L88.9872 27.4008H91.4373V0.519402H88.6218V12.0284H88.5848C87.8183 10.8442 86.7684 9.86984 85.5303 9.19374C84.2784 8.5182 82.8752 8.17224 81.4527 8.18841C79.7632 8.1644 78.0998 8.60734 76.6459 9.46842C75.2187 10.3228 74.0593 11.5598 73.2989 13.0392C72.4941 14.5637 72.0917 16.2768 72.0917 18.1785C72.0667 19.9549 72.4879 21.7092 73.3168 23.2806C74.0877 24.7583 75.2522 25.9941 76.6816 26.8514ZM85.3285 24.5839C84.2888 25.2393 83.0827 25.5821 81.8538 25.5714C80.6084 25.5871 79.3831 25.2568 78.3142 24.6174C77.2454 23.978 76.375 23.0545 75.7999 21.9498C75.2115 20.7811 74.9051 19.491 74.9051 18.1826C74.9051 16.8742 75.2115 15.584 75.7999 14.4154C76.375 13.3106 77.2454 12.3871 78.3142 11.7477C79.3831 11.1083 80.6084 10.7781 81.8538 10.7937C83.0803 10.7828 84.2849 11.1189 85.3285 11.7634C86.3777 12.4116 87.236 13.3268 87.8157 14.4154C88.4167 15.5738 88.7304 16.8597 88.7304 18.1647C88.7304 19.4698 88.4167 20.7557 87.8157 21.9141C87.2335 23.0056 86.3761 23.926 85.3285 24.5839Z"
            fill="currentColor"
          />
          <path
            d="M64.5161 8.16086C65.4858 8.14121 66.4438 8.37457 67.2958 8.83794C68.116 9.30725 68.7916 9.99277 69.2488 10.8197C69.7487 11.685 70.0595 12.7274 70.1813 13.947L67.7683 14.7518C67.6209 13.4837 67.2304 12.5081 66.5968 11.8251C65.9632 11.142 65.1341 10.8005 64.1096 10.8005C63.1976 10.7819 62.3035 11.0553 61.5578 11.5806C60.8253 12.1052 60.2645 12.8492 59.8754 13.8124C59.4863 14.7756 59.2899 15.9297 59.2862 17.2747V27.4076H56.4763V8.92859H58.8907L59.1461 11.8127H59.1832C59.657 10.7381 60.4172 9.81441 61.3806 9.14283C62.2986 8.49812 63.3944 8.15494 64.5161 8.16086Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.1928 28.1314C41.2417 28.1314 39.4856 27.6924 37.9245 26.8143C36.392 25.9655 35.1258 24.7073 34.2672 23.1803C33.3891 21.633 32.9501 19.8933 32.9501 17.9614C32.9501 16.0845 33.3525 14.408 34.1573 12.9321C34.936 11.4849 36.1014 10.2824 37.5235 9.45877C38.9619 8.61825 40.5953 8.198 42.4237 8.198C44.2036 8.198 45.7602 8.61825 47.0933 9.45877C48.4316 10.3118 49.5126 11.5129 50.2205 12.9334C50.9758 14.4103 51.3659 16.0991 51.3906 17.9999V18.53C51.3899 18.7196 51.378 18.909 51.3549 19.0972H35.8754C35.9719 20.284 36.3706 21.4264 37.0333 22.4156C37.6961 23.4048 38.601 24.208 39.6618 24.7487C40.8129 25.3138 42.0824 25.5953 43.3645 25.5697C44.6465 25.5442 45.9038 25.2123 47.0314 24.6018C48.1769 23.9563 49.0302 23.1213 49.5915 22.0967L51.9688 23.0842C51.5349 24.0836 50.8854 24.9747 50.0667 25.6936C49.1751 26.4821 48.1379 27.0886 47.0136 27.479C45.7877 27.9174 44.4947 28.1381 43.1928 28.1314ZM48.4227 16.6471C48.3009 15.4028 47.9718 14.3421 47.4352 13.4649C46.9322 12.6186 46.2043 11.9283 45.3326 11.4708C44.4207 11.0091 43.41 10.7767 42.388 10.7937C41.3192 10.7793 40.2627 11.0239 39.3089 11.5065C38.3862 11.9779 37.6041 12.6842 37.0414 13.5542C36.4401 14.4884 36.0531 15.5442 35.9084 16.6457L48.4227 16.6471Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M102.959 28.1314C101.688 28.153 100.427 27.9166 99.2505 27.4364C98.1655 26.9732 97.3246 26.3148 96.7276 25.4615C96.1328 24.6184 95.819 23.6092 95.8308 22.5774C95.8308 21.0209 96.5014 19.7043 97.8428 18.6275C99.1841 17.5508 101.293 16.8435 104.17 16.5056L108.702 15.9563C109.109 15.9311 109.496 15.77 109.801 15.4989C109.932 15.3747 110.036 15.2241 110.105 15.057C110.175 14.89 110.208 14.7101 110.203 14.5293C110.215 13.8092 109.983 13.1064 109.544 12.5352C109.106 11.9629 108.478 11.5243 107.661 11.2194C106.763 10.9008 105.815 10.7459 104.862 10.7621C103.817 10.7463 102.781 10.9586 101.827 11.3843C100.986 11.7522 100.246 12.3175 99.6694 13.0323C99.132 13.7144 98.839 14.4217 98.7904 15.1542L96.2304 14.0926C96.3522 13.0699 96.8036 12.1085 97.5846 11.2085C98.3656 10.3084 99.4016 9.58328 100.693 9.03301C102.049 8.47124 103.504 8.19089 104.972 8.20897C106.529 8.20897 107.918 8.48915 109.142 9.04949C110.365 9.60983 111.305 10.3927 111.961 11.398C112.619 12.4106 112.948 13.6009 112.949 14.9688V24.2969C112.935 24.6209 113.039 24.939 113.241 25.1923C113.329 25.3024 113.44 25.391 113.567 25.4513C113.694 25.5115 113.833 25.5418 113.973 25.5398C114.259 25.5255 114.533 25.4233 114.759 25.2473C115.06 25.0313 115.315 24.7577 115.509 24.4425L116.936 26.2347C116.521 26.8183 115.968 27.2902 115.326 27.6081C114.684 27.951 113.969 28.1329 113.241 28.1382C112.339 28.1382 111.614 27.8581 111.066 27.2977C110.512 26.7274 110.192 25.9704 110.169 25.1758L110.132 24.334H110.096C109.658 25.109 109.08 25.7955 108.39 26.3583C107.669 26.9453 106.844 27.3916 105.958 27.674C104.989 27.9856 103.977 28.14 102.959 28.1314ZM103.47 25.5714C104.657 25.5902 105.831 25.3201 106.891 24.7844C107.864 24.2958 108.686 23.5513 109.268 22.631C109.847 21.6921 110.145 20.6068 110.126 19.5037V18.1867L104.496 18.8816C102.398 19.1508 100.898 19.5958 99.9963 20.2165C99.0944 20.8373 98.6421 21.6101 98.6394 22.5348C98.6394 23.4385 99.0903 24.171 99.9922 24.7322C100.894 25.2935 102.053 25.5732 103.47 25.5714Z"
            fill="currentColor"
          />
          <path
            d="M134.139 9.05637C133.102 8.48412 131.902 8.198 130.536 8.198C128.951 8.198 127.574 8.49144 126.402 9.07834C125.211 9.68037 124.192 10.5737 123.439 11.6754H123.403L123.146 8.92864H120.586V27.4021H123.403V16.9767C123.384 15.8322 123.668 14.703 124.227 13.7039C124.758 12.7574 125.543 11.9778 126.493 11.4529C127.497 10.9066 128.625 10.6294 129.767 10.6481C131.182 10.6481 132.322 11.1114 133.187 12.038C134.052 12.9646 134.485 14.2203 134.485 15.8052V27.4007H137.34V15.4756C137.34 13.9658 137.065 12.6615 136.516 11.5628C136.002 10.5015 135.171 9.62572 134.139 9.05637Z"
            fill="currentColor"
          />
          <path
            d="M149.959 28.1314C148.789 28.1314 147.765 27.8878 146.887 27.4007C146.03 26.9336 145.32 26.2368 144.837 25.3887C144.337 24.483 144.085 23.4609 144.107 22.4263V11.2703H140.046V8.93551H144.107V3.44194L146.922 2.78409V8.92864H155.189V11.2634H146.922V22.0898C146.913 22.7283 147.051 23.3604 147.325 23.9371C147.576 24.4667 147.977 24.9116 148.477 25.2171C149.002 25.5287 149.604 25.6871 150.214 25.6744C151.171 25.6817 152.095 25.3214 152.794 24.6677C153.537 23.9975 153.995 23.0892 154.167 21.9429L156.434 22.9304C156.331 23.673 156.083 24.3881 155.703 25.0347C155.323 25.6813 154.82 26.2468 154.222 26.6989C153.005 27.6473 151.501 28.1526 149.959 28.1314Z"
            fill="currentColor"
          />
          <path d="M6.6593 8.79113L10.0557 10.49L12.7379 8.79113H6.6593Z" fill="url(#paint0_linear_87_6729)" />
          <path d="M10.0557 10.49L12.7379 8.79113L15.9242 13.4909L10.0557 10.49Z" fill="url(#paint1_linear_87_6729)" />
          <path d="M6.6593 8.79113L10.0557 10.49L16.9831 21.7147L6.6593 8.79113Z" fill="url(#paint2_linear_87_6729)" />
          <path d="M10.0557 10.49L15.9242 13.4909L16.983 21.7147L10.0557 10.49Z" fill="url(#paint3_linear_87_6729)" />
          <path
            d="M15.9242 13.4909L16.9831 21.7147L18.7575 17.7237L15.9242 13.4909Z"
            fill="url(#paint4_linear_87_6729)"
          />
          <path
            d="M16.9817 21.7216L15.9242 13.4977C15.9242 13.4977 15.9242 13.4977 15.9311 13.4895C15.9379 13.4813 15.9407 13.4895 15.942 13.4895L18.7754 17.7237C18.7754 17.7237 18.7754 17.7237 18.7754 17.7319L17.0009 21.7147L16.9817 21.7216ZM15.9393 13.5348L16.9899 21.701L18.7451 17.7182L15.9393 13.5348Z"
            fill="url(#paint5_linear_87_6729)"
          />
          <path
            d="M20.3011 21.5994L27.1516 10.5477L30.6249 9.10564L20.3011 21.5994Z"
            fill="url(#paint6_linear_87_6729)"
          />
          <path
            d="M24.5354 8.79113L27.1517 10.5477L30.625 9.10564L24.5354 8.79113Z"
            fill="url(#paint7_linear_87_6729)"
          />
          <path
            d="M27.1517 10.5559C27.1504 10.5567 27.149 10.5571 27.1476 10.5571C27.1461 10.5571 27.1447 10.5567 27.1434 10.5559L24.5258 8.79939C24.525 8.79813 24.5247 8.79671 24.5247 8.79527C24.5247 8.79382 24.525 8.7924 24.5258 8.79115H24.5354L30.625 9.10565H30.6332V9.11389L27.1599 10.5559H27.1517ZM24.5532 8.80076L27.1544 10.545L30.5879 9.11664L24.5532 8.80076Z"
            fill="url(#paint8_linear_87_6729)"
          />
          <path
            d="M24.5353 8.79113L30.7018 8.85431L30.6249 9.10564L24.5353 8.79113Z"
            fill="url(#paint9_linear_87_6729)"
          />
          <path
            d="M30.6235 9.11252L24.5325 8.79801C24.5313 8.79873 24.5299 8.79911 24.5284 8.79911C24.527 8.79911 24.5255 8.79873 24.5243 8.79801C24.5243 8.79801 24.5243 8.79115 24.5339 8.79115L30.7018 8.85432L30.6235 9.10565C30.6235 9.10565 30.629 9.11252 30.6235 9.11252Z"
            fill="url(#paint10_linear_87_6729)"
          />
          <path
            d="M18.6037 24.5412L20.3012 21.5994L18.5858 27.314L18.6037 24.5412Z"
            fill="url(#paint11_linear_87_6729)"
          />
          <path
            d="M18.5858 27.3209C18.5843 27.3216 18.5826 27.322 18.581 27.322C18.5793 27.322 18.5777 27.3216 18.5762 27.3209L18.594 24.5494L20.2915 21.6062C20.2932 21.6053 20.2951 21.6048 20.297 21.6048C20.299 21.6048 20.3009 21.6053 20.3025 21.6062C20.3038 21.6055 20.3052 21.6051 20.3066 21.6051C20.3081 21.6051 20.3095 21.6055 20.3108 21.6062L18.5954 27.314C18.5941 27.3148 18.5927 27.3152 18.5913 27.3152C18.5898 27.3152 18.5884 27.3148 18.5872 27.314L18.5858 27.3209ZM18.6133 24.5425L18.5789 27.2783L20.2874 21.6406L18.6133 24.5425Z"
            fill="#7F8B8C"
          />
          <path
            d="M20.3012 21.5994L30.625 9.10564L18.5858 27.314L20.3012 21.5994Z"
            fill="url(#paint12_linear_87_6729)"
          />
          <path
            d="M6.6593 8.79113L16.9831 21.7147L18.5721 27.3113L6.6593 8.79113Z"
            fill="url(#paint13_linear_87_6729)"
          />
          <path
            d="M18.5707 27.3196C18.5694 27.3202 18.568 27.3205 18.5666 27.3205C18.5651 27.3205 18.5637 27.3202 18.5624 27.3196L6.64966 8.79115C6.64966 8.79115 6.66064 8.79115 6.66339 8.79115L16.9858 21.7106L18.5748 27.3058C18.5748 27.3058 18.5748 27.3058 18.5652 27.3058L18.5707 27.3196Z"
            fill="url(#paint14_linear_87_6729)"
          />
          <path
            d="M16.983 21.7147L18.6036 24.5412L18.572 27.3113L16.983 21.7147Z"
            fill="url(#paint15_linear_87_6729)"
          />
          <path
            d="M18.5707 27.3195C18.5692 27.3204 18.5676 27.3208 18.5659 27.3208C18.5642 27.3208 18.5625 27.3204 18.5611 27.3195L16.972 21.723C16.972 21.723 16.972 21.7161 16.9803 21.7147C16.982 21.7138 16.9839 21.7133 16.9858 21.7133C16.9877 21.7133 16.9896 21.7138 16.9913 21.7147L18.6119 24.5425L18.5803 27.3127C18.5795 27.3146 18.5782 27.3163 18.5765 27.3175C18.5748 27.3187 18.5728 27.3194 18.5707 27.3195ZM16.9968 21.7601L18.5707 27.2701L18.5885 24.5425L16.9968 21.7601Z"
            fill="url(#paint16_linear_87_6729)"
          />
          <path
            d="M16.983 21.7147L18.6036 24.5412L18.6805 22.6953L16.983 21.7147Z"
            fill="url(#paint17_linear_87_6729)"
          />
          <path
            d="M16.983 21.7147L18.7575 17.7237L18.6805 22.6953L16.983 21.7147Z"
            fill="url(#paint18_linear_87_6729)"
          />
          <path
            d="M18.6036 24.5412L20.3012 21.5994L18.6805 22.6953L18.6036 24.5412Z"
            fill="url(#paint19_linear_87_6729)"
          />
          <path
            d="M18.6022 24.5535C18.6022 24.5535 18.594 24.5535 18.594 24.5467L18.6723 22.6995L20.2929 21.6007C20.2946 21.5993 20.2968 21.5985 20.2991 21.5985C20.3013 21.5985 20.3035 21.5993 20.3052 21.6007C20.3052 21.6007 20.3052 21.6007 20.3052 21.609L18.6118 27.3621C18.6118 27.3621 18.6064 24.5535 18.6022 24.5535Z"
            fill="url(#paint20_linear_87_6729)"
          />
          <path
            d="M20.3011 21.5994L27.1516 10.5477L23.0136 11.0092L20.3011 21.5994Z"
            fill="url(#paint21_linear_87_6729)"
          />
          <path
            d="M24.5353 8.79113L27.1516 10.5477L23.0135 11.0092L24.5353 8.79113Z"
            fill="url(#paint22_linear_87_6729)"
          />
          <path
            d="M18.7574 17.7237L20.3011 21.5994L23.0136 11.0092L18.7574 17.7237Z"
            fill="url(#paint23_linear_87_6729)"
          />
          <path
            d="M18.7575 17.7237L20.3011 21.5994L18.6805 22.6953L18.7575 17.7237Z"
            fill="url(#paint24_linear_87_6729)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_87_6729"
          x1="7.96677"
          y1="7.90804"
          x2="9.12175"
          y2="10.113"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#065034" />
          <stop offset="0.5" stopColor="#10A44A" />
          <stop offset="1" stopColor="#5FBA46" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_87_6729"
          x1="10.1779"
          y1="7.62237"
          x2="16.9411"
          y2="11.919"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#42B549" />
          <stop offset="0.64" stopColor="#378E5E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_87_6729"
          x1="7.27733"
          y1="7.88881"
          x2="17.7271"
          y2="14.3148"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="#0A6435" />
          <stop offset="0.39" stopColor="#187339" />
          <stop offset="0.74" stopColor="#3B9945" />
          <stop offset="1" stopColor="#59B94F" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_87_6729"
          x1="11.1544"
          y1="7.86272"
          x2="21.1076"
          y2="12.3099"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.11" stopColor="#088141" />
          <stop offset="0.21" stopColor="#0A8742" />
          <stop offset="0.35" stopColor="#109846" />
          <stop offset="0.49" stopColor="#19B14B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_87_6729"
          x1="15.769"
          y1="13.9043"
          x2="20.0499"
          y2="15.4435"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8ACB95" />
          <stop offset="1" stopColor="#6CC399" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_87_6729"
          x1="15.9146"
          y1="17.6028"
          x2="18.7671"
          y2="17.6028"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#69BF73" />
          <stop offset="1" stopColor="#5BBD7D" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_87_6729"
          x1="21.2653"
          y1="9.03147"
          x2="34.4107"
          y2="17.6607"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#69BD57" />
          <stop offset="0.41" stopColor="#4FB969" />
          <stop offset="0.85" stopColor="#2A8E6C" />
          <stop offset="1" stopColor="#2E6E4F" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_87_6729"
          x1="25.6698"
          y1="8.23765"
          x2="27.69"
          y2="12.3411"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5EBC6C" />
          <stop offset="0.21" stopColor="#48B873" />
          <stop offset="0.39" stopColor="#36A572" />
          <stop offset="0.48" stopColor="#2F9A74" />
          <stop offset="0.58" stopColor="#308361" />
          <stop offset="1" stopColor="#375E46" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_87_6729"
          x1="24.5079"
          y1="9.67011"
          x2="30.6181"
          y2="9.67011"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57BA62" />
          <stop offset="0.29" stopColor="#21AC6D" />
          <stop offset="0.53" stopColor="#288F68" />
          <stop offset="1" stopColor="#24AA4B" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_87_6729"
          x1="25.428"
          y1="8.53018"
          x2="25.6067"
          y2="9.81581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BAC4CA" />
          <stop offset="1" stopColor="#CBD7DD" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_87_6729"
          x1="24.5243"
          y1="8.94908"
          x2="30.7114"
          y2="8.94908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D9E54" />
          <stop offset="0.94" stopColor="#206751" />
          <stop offset="1" stopColor="#166C37" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_87_6729"
          x1="18.8152"
          y1="21.5966"
          x2="20.477"
          y2="21.987"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.35" stopColor="#18582E" />
          <stop offset="1" stopColor="#08A464" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_87_6729"
          x1="18.0378"
          y1="3.16435"
          x2="43.6229"
          y2="14.284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ACD8B1" />
          <stop offset="1" stopColor="#89CCA2" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_87_6729"
          x1="7.85553"
          y1="6.05396"
          x2="24.5513"
          y2="13.2005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6B0B5" />
          <stop offset="1" stopColor="#8D9498" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_87_6729"
          x1="6.64966"
          y1="18.0547"
          x2="18.5817"
          y2="18.0547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0C100E" />
          <stop offset="0.29" stopColor="#01422B" />
          <stop offset="1" stopColor="#10A44A" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_87_6729"
          x1="17.2412"
          y1="20.5391"
          x2="20.8181"
          y2="21.3256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12914C" />
          <stop offset="0.65" stopColor="#125F45" />
          <stop offset="1" stopColor="#143A28" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_87_6729"
          x1="16.972"
          y1="24.5137"
          x2="18.6119"
          y2="24.5137"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12914C" />
          <stop offset="0.65" stopColor="#125F45" />
          <stop offset="1" stopColor="#143A28" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_87_6729"
          x1="17.2907"
          y1="21.0473"
          x2="19.7216"
          y2="22.1164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8B2B8" />
          <stop offset="1" stopColor="#889095" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_87_6729"
          x1="18.8783"
          y1="21.0473"
          x2="16.5779"
          y2="18.0148"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5EBB69" />
          <stop offset="0.68" stopColor="#77C163" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_87_6729"
          x1="18.6036"
          y1="23.0703"
          x2="20.3012"
          y2="23.0703"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#50B748" />
          <stop offset="1" stopColor="#056540" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_87_6729"
          x1="18.5926"
          y1="24.4766"
          x2="20.3107"
          y2="24.4766"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" stopColor="#1D6549" />
          <stop offset="1" stopColor="#0E773D" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_87_6729"
          x1="19.8163"
          y1="7.415"
          x2="27.6098"
          y2="10.9164"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.59" stopColor="#9FD3A4" />
          <stop offset="0.67" stopColor="#90CD96" />
          <stop offset="0.8" stopColor="#7FC686" />
          <stop offset="0.89" stopColor="#79C480" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_87_6729"
          x1="23.3308"
          y1="8.73345"
          x2="26.8008"
          y2="11.6829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECF5EC" />
          <stop offset="1" stopColor="#E3F1E5" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_87_6729"
          x1="18.0089"
          y1="6.62117"
          x2="27.7552"
          y2="10.2198"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18582E" />
          <stop offset="0.65" stopColor="#08A064" />
          <stop offset="1" stopColor="#29B466" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_87_6729"
          x1="17.9623"
          y1="20.388"
          x2="21.6182"
          y2="19.5269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#28B24B" />
          <stop offset="0.62" stopColor="#18663F" />
          <stop offset="0.89" stopColor="#104939" />
          <stop offset="1" stopColor="#12382C" />
        </linearGradient>
        <clipPath id="clip0_87_6729">
          <rect width="156.16" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VerdantOriginal;
