import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface IArticleStore {
  editingId: string | null;
  deletingId: string | null;
  logId: string | null;
}

const initialState: IArticleStore = {
  editingId: null,
  deletingId: null,
  logId: null,
};

export const articleCategorySlice = createSlice({
  name: 'articleCategory',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setDeletingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingId: action.payload,
      };
    },
    setLogId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        logId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.articleCategory.editingId;
export const getDeletingId = (state: RootState) => state.articleCategory.deletingId;
export const getLogId = (state: RootState) => state.articleCategory.logId;

export const { setEditingId, setDeletingId, setLogId } = articleCategorySlice.actions;

export default articleCategorySlice.reducer;
