import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface IArticleStore {
  editingId: string | null;
  deletingId: string | null;
}

const initialState: IArticleStore = {
  editingId: null,
  deletingId: null,
};

export const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setDeletingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.article.editingId;
export const getDeletingId = (state: RootState) => state.article.deletingId;

export const { setEditingId, setDeletingId } = articleSlice.actions;

export default articleSlice.reducer;
