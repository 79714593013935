import { combineReducers } from '@reduxjs/toolkit';

import administratorReducer from './administrator/slice';
import nftReducer from './nft/slice';
import brandReducer from './brand/slice';
import collectionReducer from './collection/slice';
import hotCollectionReducer from './hotCollection/slice';
import userReducer from './user/slice';
import notificationReducer from './notification/slice';
import articleReducer from './article/slice';
import whiteListReducer from './whiteList/slice';
import blackListReducer from './blackList/slice';
import commissionReducer from './commission/slice';
import faqReducer from './faq/slice';
import faqCategoryReducer from './faqCategory/slice';
import aboutReducer from './about/slice';
import categoryReducer from './category/slice';
import staticalReducer from './statical/slice';
import taxReducer from './tax/slice';
import taxTypeReducer from './taxType/slice';
import promoCodeReducer from './promoCode/slice';
import couponReducer from './coupon/slice';
import vendorReducer from './vendor/slice';
import tagReducer from './tag/slice';
import bannerReducer from './banner/slice';
import newsReducer from './news/slice';
import systemReducer from './system/slice';
import articleCategoryReducer from './articles_category/slice';
import muslimReducer from './muslim/slice';

const createRootReducer = () => {
  return combineReducers({
    administrator: administratorReducer,
    nft: nftReducer,
    brand: brandReducer,
    collection: collectionReducer,
    user: userReducer,
    notification: notificationReducer,
    hotCollection: hotCollectionReducer,
    article: articleReducer,
    whiteList: whiteListReducer,
    blackList: blackListReducer,
    commission: commissionReducer,
    faq: faqReducer,
    faqCategory: faqCategoryReducer,
    about: aboutReducer,
    category: categoryReducer,
    statical: staticalReducer,
    tax: taxReducer,
    taxType: taxTypeReducer,
    promoCode: promoCodeReducer,
    coupon: couponReducer,
    vendor: vendorReducer,
    tag: tagReducer,
    banner: bannerReducer,
    news: newsReducer,
    system: systemReducer,
    articleCategory: articleCategoryReducer,
    muslim: muslimReducer,
  });
};

export default createRootReducer;
