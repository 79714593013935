import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface ITagStore {
  editingId: string | null;
  deletingId: string | null;
  logId: string | null;
}

const initialState: ITagStore = {
  editingId: null,
  deletingId: null,
  logId: null,
};

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setLogId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        logId: action.payload,
      };
    },
    setDeletingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        deletingId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.tag.editingId;
export const getLogId = (state: RootState) => state.tag.logId;
export const getDeletingId = (state: RootState) => state.tag.deletingId;

export const { setEditingId, setLogId, setDeletingId } = tagSlice.actions;

export default tagSlice.reducer;
