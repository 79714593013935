import { axiosInstance } from 'api/axios';
import { IGen2FARequest, IGen2FAResponse, ILoginRequest, IUser } from './types';

export const login = async (params: ILoginRequest): Promise<any> => {
  const { data } = await axiosInstance.post(`/admin/login`, params);
  return data;
};

export const logout = async (): Promise<any> => {
  const { data } = await axiosInstance.post('/admin/logout');
  return data;
};

export const getMe = async (): Promise<IUser> => {
  const { data } = await axiosInstance.get(`/admin/health`);
  return data;
};

export const gen2FA = async (params: IGen2FARequest): Promise<IGen2FAResponse> => {
  const { data } = await axiosInstance.post(`/admin/gen-2fa`, params);
  return data;
};
