import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface INewsStore {
  editingId: string | null;
}

const initialState: INewsStore = {
  editingId: null,
};

export const muslimSlice = createSlice({
  name: 'muslim',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.muslim.editingId;

export const { setEditingId } = muslimSlice.actions;

export default muslimSlice.reducer;
