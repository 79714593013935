import { message as messageAntd } from 'antd';

const success = (text: string) => {
  messageAntd.destroy();
  messageAntd.success(text);
};

const error = (text: string | string[]) => {
  messageAntd.destroy();
  messageAntd.error(text);
};

const info = (text: string | string[]) => {
  messageAntd.destroy();
  messageAntd.info(text);
};

export const message = { success, error, info };
