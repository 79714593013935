import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface LogProps {
  id: string | null;
  type: string;
}
export interface INFTStore {
  editingId: string | null;
  localizeId: string | null;
  rateId: string | null;
  logId: string | null;
  logRateId: LogProps | null;
  info?: {
    chain_id: string;
    contract_address: string;
  } | null;
  InfoLog?: {
    chain_id: string;
    contract_address: string;
  } | null;
}

const initialState: INFTStore = {
  localizeId: null,
  editingId: null,
  logId: null,
  rateId: null,
  logRateId: null,
  info: null,
  InfoLog: null,
};

export const nftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<[string, INFTStore['info']] | null>) => {
      if (!action.payload) {
        return {
          ...state,
          editingId: null,
          info: null,
        };
      }
      return {
        ...state,
        editingId: action.payload[0],
        info: action.payload[1],
      };
    },
    setLocalizeId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        localizeId: action.payload,
      };
    },
    setRateId: (state, action: PayloadAction<[string, INFTStore['info']] | null>) => {
      if (!action.payload) {
        return {
          ...state,
          rateId: null,
          info: null,
        };
      }
      return {
        ...state,
        rateId: action.payload[0],
        info: action.payload[1],
      };
    },
    setLogId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        logId: action.payload,
      };
    },
    setLogInfo: (state, action: PayloadAction<INFTStore['InfoLog'] | null>) => {
      if (!action.payload) {
        return {
          ...state,
          InfoLog: null,
        };
      }
      return {
        ...state,
        InfoLog: action.payload,
      };
    },
    setLogRateId: (state, action: PayloadAction<[LogProps, INFTStore['info']] | null>) => {
      if (!action.payload) {
        return {
          ...state,
          logRateId: null,
          info: null,
        };
      }
      return {
        ...state,
        logRateId: action.payload[0],
        info: action.payload[1],
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.nft.editingId;
export const getInfo = (state: RootState) => state.nft.info;
export const getLocalizeId = (state: RootState) => state.nft.localizeId;
export const getRateId = (state: RootState) => state.nft.rateId;
export const getLogId = (state: RootState) => state.nft.logId;
export const getLogInfo = (state: RootState) => state.nft.InfoLog;
export const getLogRateId = (state: RootState) => state.nft.logRateId;

export const { setEditingId, setRateId, setLogId, setLogRateId, setLocalizeId, setLogInfo } = nftSlice.actions;

export default nftSlice.reducer;
