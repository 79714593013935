import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface IPromoCodeStore {
  editingId: string | null;
  logId: string | null;
}

const initialState: IPromoCodeStore = {
  editingId: null,
  logId: null,
};

export const promoCodeSlice = createSlice({
  name: 'promoCode',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setLogId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        logId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.promoCode.editingId;
export const getLogId = (state: RootState) => state.promoCode.logId;

export const { setEditingId, setLogId } = promoCodeSlice.actions;

export default promoCodeSlice.reducer;
