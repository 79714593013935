export enum Permission {
  view_dashboard = 'view_dashboard',
  add_adminstrator = 'add_adminstrator',
  view_adminstrator = 'view_adminstrator',
  edit_delete_adminstrator = 'edit_delete_adminstrator',
  add_category = 'add_category',
  view_category = 'view_category',
  edit_delete_category = 'edit_delete_category',
  add_brand = 'add_brand',
  view_brand = 'view_brand',
  edit_delete_brand = 'edit_delete_brand',
  add_collection = 'add_collection',
  view_collection = 'view_collection',
  edit_collection = 'edit_collection',
  add_vendor = 'add_vendor',
  view_vendor = 'view_vendor',
  edit_vendor = 'edit_vendor',
  view_list_of_vendor_user = 'view_list_of_vendor_user',
  add_vendor_user = 'add_vendor_user',
  update_wallet_address_of_vendor = 'update_wallet_address_of_vendor',
  view_list_of_user = 'view_list_of_user',
  edit_user = 'edit_user',
  add_blacklist = 'add_blacklist',
  view_list_of_nfts = 'view_list_of_nfts',
  view_log_of_nfts = 'view_log_of_nfts',
  view_list_of_tags = 'view_list_of_tags',
  add_tag = 'add_tag',
  edit_delete_tag = 'edit_delete_tag',
  view_faq_category = 'view_faq_category',
  add_faq_category = 'add_faq_category',
  edit_delete_faq_category = 'edit_delete_faq_category',
  view_faq = 'view_faq',
  add_faq = 'add_faq',
  edit_delete_faq = 'edit_delete_faq',
  view_about_category = 'view_about_category',
  add_about_category = 'add_about_category',
  edit_delete_about_category = 'edit_delete_about_category',
  view_about = 'view_about',
  add_about = 'add_about',
  edit_delete_about = 'edit_delete_about',
  view_banner = 'view_banner',
  add_banner = 'add_banner',
  edit_delete_banner = 'edit_delete_banner',
  view_article = 'view_article',
  add_article = 'add_article',
  edit_delete_article = 'edit_delete_article',
  add_notification = 'add_notification',
  view_notification_manual = 'view_notification_manual',
  edit_delete_notification_manual = 'edit_delete_notification_manual',
  view_notification_automatic = 'view_notification_automatic',
  view_list_of_notification_type = 'view_list_of_notification_type',
  add_notification_type = 'add_notification_type',
  edit_delete_notification_type = 'edit_delete_notification_type',
  view_blacklist = 'view_blacklist',
  edit_black_list = 'edit_black_list',
  withdraw = 'withdraw',
  add_service_fees = 'add_service_fees',
  view_service_fee = 'view_service_fee',
  edit_service_fee = 'edit_service_fee',
  view_transaction = 'view_transaction',
  view_transaction_details = 'view_transaction_details',
  view_vendor_distribution = 'view_vendor_distribution',
  add_tax_type = 'add_tax_type',
  view_list_of_tax_type = 'view_list_of_tax_type',
  edit_delete_tax_type = 'edit_delete_tax_type',
  view_list_tax_detail = 'view_list_tax_detail',
  edit_tax_detail = 'edit_tax_detail',
  add_tax = 'add_tax',
  add_promo_code = 'add_promo_code',
  view_list_of_project = 'view_list_of_project',
  view_detail_project = 'view_detail_project',
  edit_download_detail_project = 'edit_download_detail_project',
  view_news = 'view_news',
  add_news = 'add_news',
  edit_delete_news = 'edit_delete_news',
  scmt_setting_verify = 'scmt_setting_verify',
  list_of_admin_group = 'list_of_admin_group',
  add_list_of_admin_role = 'add_list_of_admin_role',
  add_template = 'add_template',
  list_template = 'list_template',
  view_category_articles = 'view_category_articles',
  add_category_articles = 'add_category_articles',
  edit_delete_category_articles = 'edit_delete_category_articles',
  edit_muslim = 'edit_muslim',
  add_allowance = 'add_allowance',
  view_allowance = 'view_allowance',
  view_financial = 'view_financial',
}

export enum SidebarEnum {
  dashboard = 'dashboard',
  administrator = 'administrator',
  category = 'category',
  brand = 'brand',
  collection = 'collection',
  vendor = 'vendor',
  user = 'user',
  nft = 'nft',
  tag = 'tag',
  faq_category = 'faq_category',
  faq = 'faq',
  article = 'article',
  about_category = 'about_category',
  about = 'about',
  banner = 'banner',
  notification = 'notification',
  notification_type = 'notification_type',
  blacklist = 'blacklist',
  withdraw = 'withdraw',
  service_fees = 'service_fees',
  transaction = 'transaction',
  tax = 'tax',
  promo_code = 'promo_code',
  kyt = 'kyt',
  coupon = 'coupon',
  news = 'news',
  setting_smct = 'setting_smct',
  setting_kyc = 'setting_kyc',
  articles_category = 'articles_category',
  financial = 'financial',
}
