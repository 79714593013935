import { Layout as LayoutAntd } from 'antd';
import { FC } from 'react';
import { HeaderContent } from './HeaderContent';
const { Header } = LayoutAntd;

export const CustomHeader: FC = () => {
  return (
    <Header>
      <HeaderContent />
    </Header>
  );
};
