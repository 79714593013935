import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface ICouponStore {
  editingId: string | null;
  logId: string | null;
}

const initialState: ICouponStore = {
  editingId: null,
  logId: null,
};

export const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    setEditingId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        editingId: action.payload,
      };
    },
    setLogId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        logId: action.payload,
      };
    },
  },
});

export const getEditingId = (state: RootState) => state.coupon.editingId;
export const getLogId = (state: RootState) => state.coupon.logId;

export const { setEditingId, setLogId } = couponSlice.actions;

export default couponSlice.reducer;
